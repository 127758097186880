import { ECConvDays, ReportType } from 'core/report/ReportData';
import { ReportDataProvider } from './ReportDataProvider';
import { GeneralReportDataProvider } from './GeneralReportDataProvider';
import { ECommerceReportDataProvider } from './ECommerceReportDataProvider';
import { SelectOptions } from 'components/commonType';

export function getDataProvider (
  reportType: ReportType,
  onDimensionNameClick: (dimension: string, rowNameValue: SelectOptions) => void,
  onDateClick: (date: string) => void,
  ecConvDays?: ECConvDays
): ReportDataProvider {
  return reportType === ReportType.PERFORMANCE
    ? new GeneralReportDataProvider(
        onDimensionNameClick,
        onDateClick
      )
    : new ECommerceReportDataProvider(
        onDimensionNameClick,
        onDateClick,
        ecConvDays
      );
}
