import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import i18n from 'i18next';
import styles from './productNativeForm.module.scss';
import { ProductNativeFormProps } from './ProductNativeFormModel';
import { connect, FormikContextType } from 'formik';
import { FormConfig } from 'components/form/FormConfig';
import { FormContent } from 'components/form/Form';
import _ from 'lodash';
import { ProductHome } from 'containers/RetailRMN/Products/ProductHome';
import { ProductListDataContext } from 'containers/RetailRMN/Products/ProductListDataContext';
import Tags from 'components/Tags/Tags';
import { SelectOptions } from 'components/commonType';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import { FormikField } from 'components/form/field/FormikField';
import { useLocation } from 'react-router-dom';

export const ProductNativeForm: React.FunctionComponent<ProductNativeFormProps & { formik: FormikContextType<any> }> = ({
  getInitFormConfig,
  basicFields,
  ...props
}) => {

  const revealedBasicFields = useMemo(() => {
    const nameField = basicFields.find((field) => field.props.name === 'name');
    if (nameField) {
      nameField.props.validate = undefined;
    }
    const creativeTypeField = basicFields.find((field) => field.props.name === 'creativeType');
    const needShowCreativeType = creativeTypeField && creativeTypeField.props.options.length > 1;
    let hideFileds = ['name'];
    if (!needShowCreativeType) {
      hideFileds.push('creativeType');
    }
    return basicFields.filter((field) => {
      return !hideFileds.includes(field.props.name);
    });
  }, [basicFields]);
  const { values, setFieldValue } = props.formik;
  const advertiserId = values.advertiserId;
  const location = useLocation();
  const defaultFilters = useMemo(() => _.get(location.state, 'filters', {
    advertiser: advertiserId
  }), [advertiserId, location.state]);
  const [formConfig, setFormConfig] = useState<FormConfig>(getInitFormConfig());

  const {
    flowPageModel,
    products,
    filterSet,
    handleRemoveProduct,
    handleProductsButtonClick,
    handleSelectedProducts,
    onModalConfirm,
    onModalCancel,
    validateProducts,
    handleFilterChanged
  } = props.model;

  const productListModalData = useMemo(() => {
    const modalData = {
      render: () =>
        <ProductListDataContext.Provider
          value={{
            selectedProducts: products,
            handleSelectedProducts,
            handleFilterChanged
          }}
        >
          <ProductHome
            defaultFilters={filterSet || defaultFilters}
          />
        </ProductListDataContext.Provider>,
      props: {
        title: '',
        primaryButton: {
          title: i18n.t<string>('common.buttons.confirm'),
          callback: onModalConfirm
        },
        secondaryButton: {
          title: i18n.t<string>('common.buttons.cancel'),
          callback: onModalCancel
        },
        dismiss: onModalCancel,
        className: styles.productListModal
      }
    };
    return modalData;
  }, [
    filterSet,
    defaultFilters,
    products,
    handleSelectedProducts,
    handleFilterChanged,
    onModalConfirm,
    onModalCancel
  ]);

  const renderProductsField = useCallback(() => {
    const onModalOpen = _.partial(handleProductsButtonClick, productListModalData);
    const productsOptions: SelectOptions[] = products.map((product) => ({
      label: product.name,
      value: product.productId.toString()
    }));
    const renderFieldContent = () => (
      <Fragment>
        {products.length > 0 ?
          <div className={styles.tagsContainer}>
            <Tags
              value={productsOptions}
              disableInput
              onChange={handleRemoveProduct}
              className={styles.tags}
            />
            <div className={styles.editIcon}>
              <IconWithTooltip
                icon={faPencilAlt}
                tooltipProps={{
                  id: `productNativeFormEditProducts`,
                  tooltip: i18n.t<string>('creativeSetupFlow.labels.editProductsHint')
                }}
                onClick={onModalOpen}
              />
            </div>
          </div> :
          <div className={styles.selectProductsRow}>
            <button
              type='button'
              className='btn btn-secondary btn-sm'
              onClick={onModalOpen}
            >
              {i18n.t<string>('creativeSetupFlow.labels.selectProducts')}
            </button>
            <FormikField.Label
              isFlexibleContent={true}
              fieldContentWidth={20}
              inputColSm={3}
              name='selectProducts'
              validate={validateProducts}
            />
          </div>}
      </Fragment>
    );
    return (
      <Fragment>
        {!flowPageModel.isModalOpen && renderFieldContent()}
      </Fragment>
    );
  }, [
    flowPageModel.isModalOpen,
    products,
    productListModalData,
    handleProductsButtonClick,
    handleRemoveProduct,
    validateProducts
  ]);

  useEffect(() => {
    if (flowPageModel.type === 'create') {
      setFieldValue('typeProperties.products', products);
    }
    setFormConfig(new FormConfig.Builder()
      .addSection(
        new FormConfig.SectionBuilder(
          new FormConfig.FieldsBuilder([...revealedBasicFields])
            .addFormikCustom({
              name: 'typeProperties.products',
              label: i18n.t<string>('creativeSetupFlow.labels.products'),
              render: renderProductsField,
              formGroupClassName: styles.productsFieldContainer
            }, flowPageModel.type === 'edit')
            .addFormikLabel({
              name: 'typeProperties.product.name',
              label: i18n.t<string>('creativeSetupFlow.labels.product')
            }, flowPageModel.type === 'create')
            .build()
        )
        .withTitle(i18n.t<string>('creativeSetupFlow.labels.creativeBasicInfo'))
        .build()
      ).build()
    );
  }, [
    flowPageModel.type,
    products,
    revealedBasicFields,
    setFieldValue,
    renderProductsField
  ]);

  return (
    <FormContent
      formConfig={formConfig}
    />
  );
};

export default connect(ProductNativeForm);
