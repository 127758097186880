import _ from 'lodash';
import i18n from 'i18n';

export function getByteLength (targetString: string): number {
  let cnt = 0;
  const handledTargetString = targetString.replace(/[\r\n|\n|\r]{'{1,}'}/g,' ');
  for (let i = 0; i < handledTargetString.length; i++) {
    let char = handledTargetString.charCodeAt(i);
    cnt += (char >= 0x2E80 && char <= 0xFFEF) ? 2 : 1;
  }

  return cnt;
}

export function numberWithCommas (targetNumber: number | string | undefined): string {
  if (_.isNull(targetNumber) || targetNumber === undefined) {
    return '';
  }
  return targetNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function formatBytes (bytes: number): string {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const sizeIndex = Math.floor(Math.log(bytes) / Math.log(k));

  return `${(bytes / Math.pow(k, sizeIndex)).toFixed(2)} ${sizes[sizeIndex]}`;
}

export function getNumberRangeIndexHint (
  rangeIndex: number,
  margin: number,
  threshold: number,
  defaultValue: string = '-',
  i18nFloor: string = 'suggestKeywordsList.labels.postfix.request.floor',
  i18nCeiling: string = 'suggestKeywordsList.labels.postfix.request.ceiling',
  i18nUnit: string = 'suggestKeywordsList.labels.postfix.request.counts'
): string {
  if (_.isNil(rangeIndex) || rangeIndex < 0 || margin === 0) return defaultValue;
  let value = defaultValue;
  const min = 0;
  const max = threshold / margin;
  const index = Math.max(min, Math.min(max, rangeIndex));
  switch (rangeIndex) {
    case min:
      value = `${margin} ${i18n.t<string>(i18nFloor)}`;
      break;
    case max:
      value = `${threshold} ${i18n.t<string>(i18nCeiling)}`;
      break;
    default:
      value = `${index * margin}-${(index + 1) * margin} ${i18n.t<string>(i18nUnit)}`;
      break;
  }
  return value;
}
