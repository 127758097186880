import React, { useCallback } from 'react';
import moment from 'moment';
import i18n from 'i18n';
import { ReportGran, ReportType } from 'core/report/ReportData';
import { DateRangePicker } from 'components/DateRangePicker/DateRangePicker';
import ToggleButtons from 'components/ToggleButtons/ToggleButtons';
import styles from './performanceReportDimension.module.scss';
import { ReportContentModel } from '../ReportContentModel';
import { Trans } from 'react-i18next';
import { CustomField } from 'components/form/field/CustomField';
import { ToggleButtonField } from 'components/form/field/ToggleButtonField';

export const PerformanceReportDimension: React.FC<{ model: ReportContentModel }> = ({ model }) => {

  const renderScheduling = useCallback(() => {
    const endDateMoment = moment(model.state.to);
    const startDateMoment = moment(model.state.from);
    const days = endDateMoment.diff(startDateMoment, 'days');
    const hours = endDateMoment.diff(startDateMoment, 'hours');
    if (days > 0) {
      return (
        <Trans i18nKey='campaign.descriptions.campaignSchedulingDay'>
          Total <span className='text-dark'>{{ days: days + 1 }} days</span>
        </Trans>
      );
    } else if (days === 0) {
      return (
        <Trans i18nKey='campaign.descriptions.campaignSchedulingHour'>
          Total <span className='text-dark'>{{ hours: hours + 1 }} hours</span>
        </Trans>
      );
    }
  }, [model.state.from, model.state.to]);

  const updateEcConvDays = useCallback((value: string | number) => {
    model.updateEcConvDays(value.toString());
  }, [model]);

  return (
    <>
      <ToggleButtonField
        label={i18n.t<string>('report.labels.reportType')}
        name='reportType'
        value={model.state.reportType}
        options={model.reportTypes}
        onChange={model.updateReportType}
      />
      <CustomField
        label={i18n.t<string>('report.labels.reportGran')}
        name='reportGran'
      >
        <div className={styles.reportGran}>
          {model.reportGrans.length > 1 &&
            <ToggleButtons
              name={'reportGran'}
              value={model.state.gran}
              options={model.reportGrans}
              onChange={model.updateGran}
            />
          }
          <DateRangePicker
            startDate={model.state.from}
            endDate={model.state.to}
            format={model.state.gran === ReportGran.HOUR ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD'}
            onChange={model.updateDayRange}
            showTimePicker={model.state.gran === ReportGran.HOUR}
            shortcuts={model.dateTimeShortCut()}
          />
          {model.state.dayRangeError && <span className={styles.errorHint}>{model.state.dayRangeError}</span>}
          <span className={styles.hint}>{renderScheduling()}</span>
        </div>
      </CustomField>
      {model.state.reportType === ReportType.E_COMMERCE && model.state.ecConvDays && (
        <CustomField
          label={i18n.t<string>('report.labels.ecConvDays')}
          name='ecConvDays'
        >
          <ToggleButtons
            name={'ecConvDays'}
            value={model.state.ecConvDays?.toString()}
            options={model.ecConvDaysOptions}
            onChange={updateEcConvDays}
          />
        </CustomField>
      )}
    </>
  );
};
