import { Currency } from 'core/agency/Agency';

export enum ReportDimension {
  MONTH = 'month',
  DAY = 'day',
  HOUR = 'hour',
  AGENCY = 'agencyId',
  ADVERTISER = 'advertiserId',
  ORDER = 'adsOrderId',
  CAMPAIGN = 'campaignId',
  L2_CHANNEL = 'l2ChannelId',
  L1_OBJECT = 'l1ObjectId',
  CAMPAIGN_TYPE = 'adType',
  CREATIVE = 'creativeId',
  CREATIVE_TYPE = 'creativeType',
  DEVICE_TYPE = 'deviceType',
  PRODUCT = 'productId',
  SEARCH_KEYWORD = 'searchKeyword'
}

export enum ReportType {
  PERFORMANCE = 'performance',
  E_COMMERCE = 'eCommerce'
}

export enum ReportGran {
  MONTH = 'month',
  DAY = 'day',
  HOUR = 'hour'
}

export enum Metrics {
  IMPRES = 'impres',
  CLICKS = 'clicks',
  CTR = 'ctr',
  UNDER_BID_FLOOR = 'underBidFloor',
  SPENT = 'spent',
  MEDIA_COST = 'mediaCost',
  MEDIA_SPENT = 'mediaSpent',
  CPM = 'cpm',
  CPC = 'cpc'
}

export type ReportRecord = {
  dimensionID?: string;
  dimensionName: string;
  clicks: number;
  dataCost: number;
  impres: number;
  mediaCost: number;
  mediaSpent?: number;
  spent: number;
  underBidFloor?: number;
  winTotal: number;
  tags: string[];
  parentId?: number | string;
  extra?: {[key: string]: any};
  directAddToCart?: number;
  directOrder?: number;
  directProductSalesCount?: number;
  directProductSalesAmount?: number;
  indirectAddToCart?: number;
  indirectOrder?: number;
  indirectProductSalesCount?: number;
  indirectProductSalesAmount?: number;
};

export type ReportCompareRecord = ReportRecord & {
  compareID: string;
  compareName: string;
};

export type ReportData = {
  allowMetrics: Array<Metrics>;
  dimension: ReportDimension;
  filter: any;
  from: string;
  to: string;
  records: Array<ReportRecord>;
  currency: Currency;
};

export enum ECConvDays {
  DAY1 = 'DAY1',
  DAY7 = 'DAY7',
  DAY28 = 'DAY28'
}

export type BaseReportTableData = {
  id: string;
  key: string;
  name: string;
  parentId?: number | string;
  extra: any;
  tags: string[];
};

export type RtbReportTableData = {
  impres: number;
  clicks: number;
  cpc: number;
  cpm: number;
  ctr: number;
  spent: number;
  mediaCost: number;
  mediaSpent: number;
} & BaseReportTableData;

export type ECReportTableData = {
  impres: number;
  clicks: number;
  ctr: number;
  spent: number;
  directAddToCart: number;
  directOrder: number;
  directProductSalesCount: number;
  directProductSalesAmount: number;
  directConversionRate: number;
  directRoas: number;
  indirectAddToCart: number;
  indirectOrder: number;
  indirectProductSalesCount: number;
  indirectProductSalesAmount: number;
  indirectConversionRate: number;
  indirectRoas: number;
} & BaseReportTableData;

export type ReportTableData = RtbReportTableData | ECReportTableData;
