import display from 'assets/campaign/adType-display.png';
import display2x from 'assets/campaign/adType-display@2x.png';
import { AdType, AD_TYPE_MAP_CREATIVE_TYPE } from 'core/rtbCampaign/RtbCampaign';
import { DefaultCampaignAdTypeButtonModel, CampaignAdTypeButtonModel } from '../CampaignAdTypeButton/CampaignAdTypeButtonModel';
import { UpdateEventListener, FireableUpdateEventListener } from 'utils/UpdateEventListener';
import { AddonFeatureType } from 'core/agency/AddonFeature';
import { RtbCampaignSetupFlowPageModel } from '../RtbCampaignSetupFlowPageModel';
import i18n from 'i18next';
import _ from 'lodash';
import { L1ObjectChannel, L1ObjectObjective } from 'core/l1Object/L1Object';
import config from 'config';

export const CHANNEL_MAP_CAMPAIGN_TYPE = {
  [L1ObjectChannel.RTB]:
    config.env === 'prod' ? [AdType.KEYWORD] : [AdType.DISPLAY, AdType.KEYWORD]
};

export interface ChooseAdTypeStepModel {
  readonly event: UpdateEventListener<ChooseAdTypeStepModel>;
  readonly state: ChooseAdTypeStepState;
  readonly flowModel: RtbCampaignSetupFlowPageModel;
  readonly getAdTypeButtonModels: () => CampaignAdTypeButtonModel[];
  readonly showChangeAdTypeAlertModal: (confirmFuc) => void;
  onAdTypeChange: (adType: AdType) => void;
  backToL1ObjectDetail: () => void;
}

export type ChooseAdTypeStepProps = {
  readonly model: ChooseAdTypeStepModel;
};

export type ChooseAdTypeStepState = {
  readonly changeAdTypeAlertModal?: any;
};

export class DefaultChooseAdTypeStepModel implements ChooseAdTypeStepModel {
  event: FireableUpdateEventListener<ChooseAdTypeStepModel>;
  adType?: AdType;
  changeAdTypeAlertModal?: any;
  flowModel: RtbCampaignSetupFlowPageModel;

  constructor (
    flowModel: RtbCampaignSetupFlowPageModel,
    private goNext: () => void
  ) {
    this.flowModel = flowModel;
    this.adType = flowModel.state.campaign.basic.adType;
    this.event = new FireableUpdateEventListener<ChooseAdTypeStepModel>();
  }

  get state () {
    return {
      changeAdTypeAlertModal: this.changeAdTypeAlertModal
    };
  }

  get adTypeOptions () {
    const addonFeatureManager = this.flowModel.addonFeatureManager;
    const objective = _.get(this.flowModel.l1Object, 'objective');
    const channel = _.get(this.flowModel.l1Object, 'channel');
    const canSelectPmp = _.get(this.flowModel, 'canSelectPmp');
    const objectiveDependancy = {
      [AdType.KEYWORD]: [
        L1ObjectObjective.AWARENESS,
        L1ObjectObjective.TRAFFIC
      ]
    };
    const allowAdTypes = {
      ...AD_TYPE_MAP_CREATIVE_TYPE
    };
    return Object.keys(AD_TYPE_MAP_CREATIVE_TYPE).reduce((allowAdType, adType) => {
      const allowCreativeTypes = AD_TYPE_MAP_CREATIVE_TYPE[adType];
      const hasPermission = allowCreativeTypes.some((creativeType) => {
        if (!allowAdTypes[adType] || !allowAdTypes[adType].includes(creativeType)) {
          return false;
        }
        return addonFeatureManager.isFeatureEnable(`option_${creativeType}` as AddonFeatureType);
      });
      const objectiveCompatible = !objectiveDependancy[adType] || objectiveDependancy[adType].includes(objective);
      const channelCompatible = !(channel in CHANNEL_MAP_CAMPAIGN_TYPE) || CHANNEL_MAP_CAMPAIGN_TYPE[channel].map(adType => adType.toString()).includes(adType);
      if ((adType === AdType.PMP && !canSelectPmp)) {
        return allowAdType;
      }
      if (hasPermission && objectiveCompatible && channelCompatible) {
        allowAdType.push(AdType[adType]);
      }
      return allowAdType;
    }, [] as Array<AdType>);
  }

  getAdTypeButtonModels = () => {
    return this.adTypeOptions.map((adTypeOption) => {
      return new DefaultCampaignAdTypeButtonModel(
        this.getAdTypeButtonData(adTypeOption),
        adTypeOption === this.adType,
        _.partial(this.onAdTypeButtonClick, adTypeOption)
      );
    });
  }

  onAdTypeButtonClick = (adType: AdType) => {
    const campaign = this.flowModel.state.campaign;
    const currentAdType = _.get(campaign, 'basic.adType');
    if (currentAdType !== undefined && adType !== currentAdType) {
      this.showChangeAdTypeAlertModal(() => {
        this.onAdTypeChange(adType);
      });
    } else {
      this.onAdTypeChange(adType);
    }
  }

  onAdTypeChange (adType: AdType) {
    this.adType = adType;
    this.flowModel.onAdTypeChange(adType);
    this.goNext();
  }

  getAdTypeButtonData (adTypeOption: AdType) {
    switch (adTypeOption) {
      case AdType.DISPLAY:
        return {
          icon: display,
          icon2x: display2x,
          title: 'campaign.buttons.adTypeDisplayTitle',
          description: 'campaign.buttons.adTypeDisplayDesc'
        };
      case AdType.KEYWORD:
        return {
          icon: display,
          icon2x: display2x,
          title: 'campaign.buttons.adTypeKeywordTitle',
          description: 'campaign.buttons.adTypeKeywordDesc'
        };
      case AdType.PMP:
        return {
          icon: display,
          icon2x: display2x,
          title: 'campaign.buttons.adTypePmpTitle',
          description: ''
        };
      default:
        return {
          icon: display,
          icon2x: display2x,
          title: 'campaign.buttons.adTypeDisplayTitle',
          description: 'Image / Product Native '
        };
    }
  }

  showChangeAdTypeAlertModal = (confirmFuc) => {
    this.changeAdTypeAlertModal = {
      title: i18n.t<string>('common.warning'),
      message: i18n.t<string>('campaign.descriptions.adTypeChangeAlert'),
      primaryBtn: {
        title: i18n.t<string>('common.buttons.ok'),
        callback: () => {
          confirmFuc();
          this.hideModal();
        }
      },
      secondaryBtn: {
        title: i18n.t<string>('common.buttons.cancel'),
        callback: this.hideModal
      }
    };
    this.updateState();
  }

  hideModal = () => {
    this.changeAdTypeAlertModal = undefined;
    this.updateState();
  }

  backToL1ObjectDetail = () => {
    this.flowModel.setFinishedRedirectPath(`/orders/${this.flowModel.order.orderNumber}/campaign-groups/${this.flowModel.l1Object.l1ObjectId}`);
  }

  updateState () {
    this.event.fireEvent(this);
  }
}
