import {
  RtbCampaignWebService,
  RestfulCampaignWebService,
  CampaignAnalyticsRequest
} from 'ws/RtbCampaignWebService';
import {
  RtbCampaign,
  RtbCampaignBasic,
  RtbOptimize,
  RtbCampaignPlanType,
  AdType,
  RTBCAMPAIGN_DEFAULT_AGE_MAX,
  RTBCAMPAIGN_DEFAULT_AGE_MIN,
  CampaignAnalytics
} from './RtbCampaign';
import _ from 'lodash';
import { SelectOptions } from 'components/commonType';
import LocalDateTimeUtil from 'utils/LocalDateTimeUtil';
import moment from 'moment';
import { L2ObjectOptimizationGoal } from 'core/l2Object/L2Object';
import i18n from 'i18n';
import { getLimitationContentLabel, toServerStructure } from 'utils/LimitationUtil';
import { ageMaxOptions, ageMinOptions, getRtbAgeGroupsByAgeRange } from 'core/limitation/l2ObjectTAOptions';
import { CampaignLimitation, Limitation } from 'core/limitation/Limitation';
export interface RtbCampaignManager {
  getCampaignsOfGroup (l1ObjectId: number | string): Promise<RtbCampaignBasic[]>;
  getCampaign (campaignId): Promise<RtbCampaign>;
  getNoCidCampaign (campaignId): Promise<RtbCampaign>;
  createCampaign (campaign: RtbCampaign, l1ObjectId?: number | string): Promise<number>;
  createPmpCampaign (campaign: RtbCampaign, l1ObjectId: number | string, pmpId: number, creative: any): Promise<number>;
  updateCampaign (campaign: RtbCampaign, l1ObjectId?: number | string): Promise<number>;
  splitCampaign (campaign: RtbCampaign, origCamaignId, l1ObjectId?: number | string): Promise<number>;
  updateCampaignState (campaignData: {
    l2ChannelId: (number | string)
  }[], state: 'activate' | 'deactivate'): Promise<void>;
  deleteCampaigns (campaignIds: Array<number>): Promise<void>;
  getCampaignOptions (from?: string, to?: string): Promise<Array<SelectOptions>>;
  getCampaignName (campaignId: number): Promise<string | null>;
  getOrderNumber (campaignId: string | number): Promise<string>;
  getMinBudgetOfCampaign (campaign: RtbCampaignBasic, budgetMinimumPerDay: number);
  prepareCreateCampaignPayload (campaign: RtbCampaign);
  getOptimizeDes (l2ObjectOptimizationGoal: L2ObjectOptimizationGoal): 'CPC' | 'CPM';
  getAdTypeOptions (): Promise<SelectOptions[]>;
  getLimitationSummaryData (limitation: any): any;
  checkOptimizeSameAsPriceModel (priceModel: RtbCampaignPlanType, optimize: L2ObjectOptimizationGoal): boolean;
  getDailyBudgetCampaignProgressRate (budget, spents, startDate, endDate): {
    executeRate: number,
    predictRate: number
  };
  getCampaignProgressRate (budget, currencyRate, olapActualSpent, olapExpectSpent): {
    executeRate: number,
    predictRate: number
  };
  getCampaignTypeDes (type: AdType): string;
  getRedirectInfo (campaignId: number | string): Promise<{
    orderNumber: string,
    l1ObjectId: string
  }>;
  getCampaignAnalytics (request: CampaignAnalyticsRequest, l1ObjectId: number | string): Promise<CampaignAnalytics>;
  getCampaignAnalyticsHint (campaignAnalytics: CampaignAnalytics | undefined, fieldName: string): string;
}

export class DefaultRtbCampaignManager implements RtbCampaignManager {
  webService: RtbCampaignWebService;

  constructor (
    webService: RtbCampaignWebService = new RestfulCampaignWebService()
  ) {
    this.webService = webService;
  }

  async getCampaign (campaignId: number): Promise<RtbCampaign> {
    return this.webService.getCampaign(campaignId);
  }

  async getNoCidCampaign (campaignId: number): Promise<RtbCampaign> {
    const campaign = await this.getCampaign(campaignId);
    _.unset(campaign, 'basic.id');
    _.set(campaign, 'basic.name', campaign.basic.name + '_' + LocalDateTimeUtil.getYMDHMS());
    return campaign;
  }

  async getCampaignsOfGroup (l1ObjectId: number | string): Promise<RtbCampaignBasic[]> {
    return this.webService.getCampaignsOfGroup(l1ObjectId);
  }

  async createCampaign (campaign: RtbCampaign, l1ObjectId?: number | string): Promise<number> {
    const payload = this.prepareCreateCampaignPayload(campaign);
    _.set(payload, 'campaign.l1ObjectId', l1ObjectId);
    return this.webService.createCampaign(payload, l1ObjectId);
  }

  async createPmpCampaign (campaign: RtbCampaign, l1ObjectId: number | string, pmpId: number, creative: any): Promise<number> {
    const campaignPayload = this.prepareCreateCampaignPayload(campaign);
    _.set(campaignPayload, 'campaign.l1ObjectId', l1ObjectId);

    const dealIdTA = _.defaultTo(campaign.limitations.other, []).find(limitation => limitation.type === 'dealId');
    const payload = {
      ...campaignPayload,
      campaign: _.omit({
        ...campaignPayload.campaign,
        dealIds: dealIdTA ? (dealIdTA.value as SelectOptions[]).map(limit => limit.value) : [],
        pmpId
      }, ['ageMin', 'ageMax']),
      limitations: [],
      creatives: [creative]
    };
    return this.webService.createPmpCampaign(payload, l1ObjectId);
  }

  async updateCampaign (campaign: RtbCampaign, l1ObjectId?: number | string): Promise<number> {
    const payload = this.wrapCampaignForServer(_.cloneDeep(campaign));
    _.set(payload, 'campaign.l1ObjectId', l1ObjectId);
    return this.webService.updateCampaign(payload, l1ObjectId);
  }

  async splitCampaign (campaign: RtbCampaign, origCamaignId, l1ObjectId?: number | string): Promise<number> {
    const payload = this.prepareCreateCampaignPayload(campaign);
    _.set(payload, 'campaign.l1ObjectId', l1ObjectId);
    return this.webService.splitCampaign(payload, origCamaignId, l1ObjectId);
  }

  async updateCampaignState (campaignData: {
    l2ChannelId: (number | string)
  }[], state: 'activate' | 'deactivate'): Promise<void> {
    await this.webService.updateCampaignState(campaignData, state);
  }

  async deleteCampaigns (campaignIds: Array<number>): Promise<void> {
    return this.webService.deleteCampaigns(campaignIds);
  }

  async getCampaignOptions (from?: string, to?: string): Promise<Array<SelectOptions>> {
    return this.webService.getCampaignOptions(from, to);
  }

  async getCampaignName (campaignId: number): Promise<string | null> {
    return this.webService.getCampaignName(campaignId);
  }

  async getOrderNumber (campaignId: string | number): Promise<string> {
    return this.webService.getOrderNumber(campaignId);
  }

  async getAdTypeOptions (): Promise<SelectOptions[]> {
    return this.webService.getAdTypeOptions();
  }

  async getRedirectInfo (campaignId: number | string): Promise<{
    orderNumber: string,
    l1ObjectId: string
  }> {
    return this.webService.getRedirectInfo(campaignId);
  }

  async getCampaignAnalytics (request: CampaignAnalyticsRequest, l1ObjectId: number | string): Promise<CampaignAnalytics> {
    return this.webService.getCampaignAnalytics(request, l1ObjectId);
  }

  getCampaignAnalyticsHint (campaignAnalytics: CampaignAnalytics | undefined, fieldName: string): string {
    const neglectedFields = ['brandAwarenessGrowth', 'trafficGrowth', 'keywordSearchVolume'];
    const analytics = _.get(campaignAnalytics, fieldName);
    if (!campaignAnalytics || !analytics || neglectedFields.includes(fieldName)) {
      return '';
    }
    let value: string | number | null;
    switch (fieldName) {
      case 'lifetimeBudgetRank':
      case 'dailyBudgetRank':
        const percentage = Math.round(analytics * 100);
        value = (100 - percentage) > 20 ? `${100 - percentage}%` : null;
        break;
      default:
        value = analytics;
        break;
    }
    if (!value) {
      return '';
    }
    return i18n.t<string>(`campaignInfo.analytics.${fieldName}`, { value });
  }

  getMinBudgetOfCampaign (campaign: RtbCampaignBasic, budgetMinimumPerDay: number) {
    const startDate = moment(campaign.startDate);
    const endDate = moment(campaign.endDate);
    const scheduleDateCount = endDate.diff(startDate, 'days') + 1;
    return budgetMinimumPerDay * scheduleDateCount;
  }

  getNativeOptimizeValue (l2ObjectOptimizationGoal: L2ObjectOptimizationGoal) {
    const nativeOptimizeMap = {
      [L2ObjectOptimizationGoal.IMPRESSIONS]: RtbOptimize.IMPRESSIONS,
      [L2ObjectOptimizationGoal.CLICKS]: RtbOptimize.CLICKS
    };
    return nativeOptimizeMap[l2ObjectOptimizationGoal];
  }

  getOptimizeDes (l2ObjectOptimizationGoal: L2ObjectOptimizationGoal) {
    const legacyOptimizeMap = {
      [L2ObjectOptimizationGoal.IMPRESSIONS]: 'CPM',
      [L2ObjectOptimizationGoal.CLICKS]: 'CPC'
    };
    return legacyOptimizeMap[l2ObjectOptimizationGoal] ? legacyOptimizeMap[l2ObjectOptimizationGoal] : l2ObjectOptimizationGoal;
  }

  wrapCampaignForServer = (campaign: RtbCampaign) => {
    const ageOptValue = campaign.limitations.include && campaign.limitations.include.find(limitation => limitation.type === 'age_min') ? 'include' : 'preferred';
    const ageMinLimitation = ageOptValue === 'include'
     ? campaign.limitations.include.find(limitation => limitation.type === 'age_min') : campaign.limitations.preferred
     ? campaign.limitations.preferred.find(limitation => limitation.type === 'age_min') : undefined;
    const ageMin = ageMinLimitation ? ageMinLimitation.value : RTBCAMPAIGN_DEFAULT_AGE_MIN;
    const ageMaxLimitation = ageOptValue === 'include'
     ? campaign.limitations.include.find(limitation => limitation.type === 'age_max') : campaign.limitations.preferred
     ? campaign.limitations.preferred.find(limitation => limitation.type === 'age_max') : undefined;
    const ageMax = ageMaxLimitation ? ageMaxLimitation.value : RTBCAMPAIGN_DEFAULT_AGE_MAX;
    const isRs = [RtbCampaignPlanType.RS_CPC, RtbCampaignPlanType.RS_CPM, RtbCampaignPlanType.RS].includes(campaign.basic.priceModel);
    let result: any = {
      campaign: {
        ..._.omit(campaign.basic, ['id', 'tags']),
        priceModel: isRs ? RtbCampaignPlanType.RS : campaign.basic.priceModel,
        tags: campaign.basic.tags.join(','),
        budget: campaign.basic.budget,
        campaignId: campaign.basic.id,
        ageMin: ageMin,
        ageMax: ageMax,
        optimize: this.getNativeOptimizeValue(campaign.basic.optimize)
      },
      limitations: ageOptValue === 'include' ? toServerStructure(campaign.limitations) : toServerStructure({
        ...campaign.limitations,
        preferred: [
          ..._.filter(campaign.limitations.preferred, (limitation: Limitation) => !['age_min', 'age_max'].includes(limitation.type)),
          {
            op: 'Preferred',
            type: 'age',
            value: getRtbAgeGroupsByAgeRange(ageMin, ageMax)
          }
        ]
      }),
      creatives: campaign.products ? campaign.products.map(product => ({
        typeProperties: {
          product: JSON.stringify(product)
        }
      })) : undefined
    };
    return result;
  }

  prepareCreateCampaignPayload (campaign: RtbCampaign) {
    const prepareCreate = _.flow([_.cloneDeep, this.wrapCampaignForServer]);
    return prepareCreate(campaign);
  }

  getAgeLimitationSummary = (limitations: Limitation[]) => {
    const ageMinLimitation = limitations.find(limitation => limitation.type === 'age_min');
    const ageMaxLimitation = limitations.find(limitation => limitation.type === 'age_max');
    if (ageMinLimitation && ageMaxLimitation) {
      const ageMinOption = ageMinOptions.find(option => option.value === _.get(ageMinLimitation, 'value'));
      const ageMaxOption = ageMaxOptions.find(option => option.value === _.get(ageMaxLimitation, 'value'));
      const ageGroupsHintGetter = getRtbAgeGroupsByAgeRange;
      const ageGroupsHint = ageGroupsHintGetter(_.get(ageMinOption, 'value', 0), _.get(ageMaxOption, 'value', 0))
                              .map(group => group.label)
                              .join(',');
      return {
        label: i18n.t<string>('limitation.labels.age'),
        value: `${_.get(ageMinOption, 'label', 0)} ~ ${_.get(ageMaxOption, 'label', 0)}`,
        hint: i18n.t<string>('limitation.hints.ageGroups', { groups: ageGroupsHint })
      };
    }
    return undefined;
  }

  getLimitationContent = (limitationData: Limitation[]) => {
    if (!limitationData) {
      return [];
    }
    const normalLimitationSummary = _.compact(limitationData.map((data) => {
      const ignoreType = [
        'age',
        'age_min',
        'age_max'
      ];
      if (ignoreType.includes(data.type)) {
        return undefined;
      }

      if (!data.value) {
        return undefined;
      }

      if (Array.isArray(data.value)) {
        if (_.defaultTo(data.value, []).length === 0) {
          return undefined;
        }

        return {
          label: i18n.t<string>(`limitation.labels.${data.type}`),
          value: data.value.map((value) => getLimitationContentLabel(data.type, value)).join(', ')
        };
      }

      return {
        label: i18n.t<string>(`limitation.labels.${data.type}`),
        value: data.value
      };
    }));

    const ageLimitationSummary = this.getAgeLimitationSummary(limitationData);
    if (ageLimitationSummary) {
      return [ageLimitationSummary, ...normalLimitationSummary];
    }
    return normalLimitationSummary;
  }

  getLimitationSummaryData (limitationData: CampaignLimitation) {
    return _.omitBy({
      include: this.getLimitationContent(limitationData.include),
      preferred: this.getLimitationContent(limitationData.preferred),
      nonPreferred: this.getLimitationContent(limitationData.nonPreferred),
      exclude: this.getLimitationContent(limitationData.exclude),
      other: limitationData.other ? this.getLimitationContent(limitationData.other) : undefined
    }, _.isEmpty);
  }

  checkOptimizeSameAsPriceModel (priceModel: RtbCampaignPlanType, optimize: L2ObjectOptimizationGoal): boolean {
    return (priceModel === RtbCampaignPlanType.FCPC && optimize === L2ObjectOptimizationGoal.CLICKS) ||
      (priceModel === RtbCampaignPlanType.FCPM && optimize === L2ObjectOptimizationGoal.IMPRESSIONS);
  }

  getDailyBudgetCampaignProgressRate = (budget: number, spents: number, startDate: string, endDate: string) => {
    const startTime = moment(startDate).startOf('day');
    const endTime = moment(endDate).startOf('day');
    const now = moment().startOf('day');
    const isStart = moment().isSameOrAfter(moment(startDate));
    const isEnd = moment().isAfter(moment(endDate));

    let predictRate;
    if (!isStart) {
      predictRate = 0;
    } else if (isEnd) {
      predictRate = 1;
    } else {
      predictRate = (now.diff(startTime, 'day') + 1) / (endTime.diff(startTime, 'day') + 1);
    }

    const canCalRate = budget !== 0;
    if (canCalRate) {
      return { executeRate: spents / budget, predictRate };
    }
    return { executeRate: 0, predictRate: 0 };
  }

  getCampaignProgressRate = (budget: number, currencyRate: number, olapActualSpent: number, olapExpectedSpent: number) => {
    const budgetUsd = budget / currencyRate;
    const canCalRate = budgetUsd !== 0;
    if (canCalRate) {
      return {
        executeRate: olapActualSpent / budgetUsd,
        predictRate: olapExpectedSpent / budgetUsd
      };
    }
    return {
      executeRate: 0,
      predictRate: 0
    };
  }

  getCampaignTypeDes = (adType) => {
    if (adType in AdType) {
      return i18n.t<string>(`campaignList.labels.adType${_.upperFirst(_.camelCase(adType))}`);
    }
    return i18n.t<string>('campaignList.labels.adTypeEmpty');
  }
}
