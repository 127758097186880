import React from 'react';
import styles from './reportTable.module.scss';
import { Nav } from 'react-bootstrap';
import i18n from 'i18n';
import { StickableBootstrapTable } from 'components/Table/StickableBootstrapTable';
import { ReportDimension, ReportTableData } from 'core/report/ReportData';
import { Modal } from 'components/Modal/Modal';
import { ColumnDefinition } from 'components/TableColumn/TableColumn';

interface ReportTableProps {
  readonly dimension: ReportDimension;
  readonly tableData: ReportTableData[];
  readonly columnSettings: ColumnDefinition[];
  readonly dimensions: Array<ReportDimension>;
  readonly modalData: any;
  hideModal: () => void;
  onDimensionChange: (dimension: string) => void;
}
export class ReportTable extends React.Component<ReportTableProps> {

  renderTableTab () {
    return Object.values(this.props.dimensions).map(dimension => (
      <Nav.Item key={dimension}>
        <Nav.Link eventKey={dimension}>{i18n.t<string>(`reportTable.labels.${dimension}Dimension`)}</Nav.Link>
      </Nav.Item>
    ));
  }

  renderForm () {
    const { tableData, columnSettings } = this.props;
    return (
      <div className={styles.tableContainer}>
        <StickableBootstrapTable
          stickFirstColumn
          stickLastColumn
          keyField='key'
          data={tableData}
          columns={columnSettings}
          className={styles.reportList}
          pagination={{
            sizePerPage: 50
          }}
        />
      </div>
    );
  }

  renderModal (modalData, hideModal) {
    return (
      <Modal
        title={modalData.title}
        primaryButton={modalData.confirmBtnData && {
          title: modalData.confirmBtnData.title,
          callback: modalData.confirmBtnData.callback
        }}
        secondaryButton={{
          title: i18n.t<string>('common.buttons.close'),
          callback: hideModal
        }}
        dismiss={hideModal}
        animation={false}
      >
        {modalData.message}
      </Modal>
    );
  }

  render () {
    const { onDimensionChange, dimension, modalData, hideModal } = this.props;
    const onNavItemSelect = (seletedDimension, e) => {
      if (seletedDimension === null) {
        e.stopPropagation();
        return;
      }
      onDimensionChange(seletedDimension);
      e.stopPropagation();
    };
    return (
      <div className={styles.reportTable}>
        <Nav
          activeKey={dimension === ReportDimension.HOUR || dimension === ReportDimension.MONTH ? ReportDimension.DAY : dimension}
          onSelect={onNavItemSelect}
        >
          {this.renderTableTab()}
        </Nav>
        {this.renderForm()}
        {modalData && this.renderModal(modalData, hideModal)}
      </div>
    );
  }
}
