import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { AddonFeatureManager, LocaleMeta } from 'core';
import { LimitationManager, DefaultLimitationManager } from 'core/limitation/LimitationManager';
import { DeliverType, RtbCampaignPlanType, CreativeDeliverType, AdType, CampaignState, RTBCAMPAIGN_DEFAULT_AGE_MIN, RTBCAMPAIGN_DEFAULT_AGE_MAX, CampaignAnalytics } from 'core/rtbCampaign/RtbCampaign';
import { Order } from 'core/order/Order';
import { DefaultRtbCampaignManager, RtbCampaignManager } from 'core/rtbCampaign/RtbCampaignManager';
import { RtbCampaignBasicFormModel, RtbCampaignBasicFormModelConstructorParams } from './RtbCampaignForm/RtbCampaignBasicFormModel';
import { defaultInventorySetting, LIMITATION_TYPE, keywordAdsInventorySetting } from 'containers/Limitations/LimitationSetting/LimitationSettingData';
import { DefaultEditLimitationModel, EditLimitationModel } from 'containers/Limitations/EditLimitationModel';
import moment from 'moment';
import i18n from 'i18next';
import _ from 'lodash';
import { SelectOptions } from 'components/commonType';
import { getPriceValue } from 'helper/CurrencyHelper';
import { L1Object, L1ObjectChannel } from 'core/l1Object/L1Object';
import { UnknowAdTypeFormModel, CreateUnknowAdTypeFormModel, EditUnknowAdTypeFormModel } from './RtbCampaignForm/UnknowAdTypeFormModel';
import { BidStrategy, L2ObjectOptimizationGoal } from 'core/l2Object/L2Object';
import { DefaultRtbCampaignSummaryStepModel, RtbCampaignSummaryStepModel } from './FlowSteps/RtbCampaignSummaryStepModel';
import { DefaultDmpManager, DmpManager } from 'core/dmp/DmpManager';
import { SummaryTitleColor } from 'components/SummaryDetail/SummaryDetail';
import { CreateDisplayFormModel, DisplayFormModel, EditDisplayFormModel } from './RtbCampaignForm/DisplayFormModel';
import { CreateKeywordFormModel, EditKeywordFormModel, KeywordFormModel } from './RtbCampaignForm/KeywordFormModel';
import { CreativeManager, DefaultCreativeManager } from 'core/creative/CreativeManager';
import { CampaignBannerMapState, CreativeOfCampaign } from 'core/creative/Creative';
import { CreatePmpFormModel, EditPmpFormModel, PmpFormModel } from './RtbCampaignForm/PmpFormModel';
import { Pmp, PmpStatus } from 'core/pmp/Pmp';
import { DefaultPmpManager, PmpManager } from 'core/pmp/PmpManager';
import { CreateCreativeInL2ObjectSetupFlowPageModel } from 'containers/Creatives/CreativeSetupFlow/CreativeSetupFlowPageModel';
import { CreativeSummaryStepModel } from 'containers/Creatives/CreativeSetupFlow/FlowSteps/CreativeSummaryStepModel';
import { getLimitationValueContent } from 'utils/LimitationUtil';
import { OPERATE } from 'enum/Operate';

export interface RtbCampaignSetupFlowPageModel {
  readonly type: string;
  readonly objectType: string;
  readonly order: Order;
  readonly addonFeatureManager: AddonFeatureManager;
  readonly state: RtbCampaignSetupFlowPageState;
  readonly event: UpdateEventListener<RtbCampaignSetupFlowPageModel>;
  readonly limitationModel?: EditLimitationModel;
  readonly campaignBasicFormModel?: RtbCampaignBasicFormModel;
  readonly limitationPreSet: any;
  readonly campaignId: number | string | null;
  readonly needSelectProduct: boolean;
  readonly canSelectPmp: boolean;
  readonly refreshCampaignAnalyticsEnabled: boolean;
  readonly isPmp: boolean;
  readonly l1Object: L1Object;
  readonly localeMeta?: LocaleMeta;
  readonly creatives?: CreativeOfCampaign[];
  initWithErrorHandler (): void;
  setCampaign (campaign: any, rerender?: boolean): void;
  getRtbCampaignBasicFormModel (adType): RtbCampaignBasicFormModel | undefined;
  getLimitationModel (): EditLimitationModel;
  getTitle (): string;
  onAdTypeChange (adType): void;
  onUnmount (handler): void;
  setOrder (order: Order): void;
  setRedirectPath (redirectPath?: string): void;
  setFinishedRedirectPath (redirectPath?: string): void;
  cancel (): void;
  getRtbCampaignSummaryStepModel (goLast, goStep, goSubStep, subStepIndex: number, creativeSummaryModel?: CreativeSummaryStepModel): RtbCampaignSummaryStepModel;
  getLimitationsSummaryData (limitations): any;
  addCreative?: (campaignId: number) => void;
  setPmp: (pmp: Pmp) => void;
  getCreativeSetupFlowModel (): CreateCreativeInL2ObjectSetupFlowPageModel | undefined;
  fetchCampaignAnalytics (campaign?: any): void;
}

export type RtbCampaignSetupFlowPageProps = {
  readonly model: RtbCampaignSetupFlowPageModel;
};

export type RtbCampaignSetupFlowPageState = {
  readonly loading: boolean;
  readonly redirectPath?: string;
  readonly campaign: any;
  readonly finished: boolean;
  readonly showRetry: boolean;
  readonly pmp?: Pmp;
  readonly pmpSpaceOptions?: SelectOptions[];
  readonly pmpListOfAgency?: Pmp[];
  readonly campaignAnalytics?: CampaignAnalytics;
};

export abstract class DefaultRtbCampaignSetupFlowPageModel implements RtbCampaignSetupFlowPageModel {
  objectType: string;
  event: FireableUpdateEventListener<RtbCampaignSetupFlowPageModel>;
  loading: boolean;
  redirectPath?: string;
  addonFeatureManager: AddonFeatureManager;
  campaign: any;
  defaultCampaign: any;
  limitationPreSet: any;
  order: Order;
  campaignDisplayFormModel?: DisplayFormModel;
  campaignUnknowAdTypeFormModel?: UnknowAdTypeFormModel;
  campaignKeywordFormModel?: KeywordFormModel;
  campaignPmpFormModel?: PmpFormModel;
  campaignBasicFormModel?: RtbCampaignBasicFormModel;
  campaignSummaryModel?: RtbCampaignSummaryStepModel;
  limitationModel: any;
  rbLimitationModel: any;
  normalLimitationModel: any;
  finished: boolean;
  creatives?: CreativeOfCampaign[];
  showRetry: boolean = false;
  pmpListOfAgency?: Pmp[];
  pmp?: Pmp;
  pmpSpaceOptions?: SelectOptions[];
  campaignAnalytics?: CampaignAnalytics;

  constructor (
    order: Order,
    addonFeatureManager: AddonFeatureManager,
    public l1Object: L1Object,
    public localeMeta?: LocaleMeta,
    protected manager: RtbCampaignManager= new DefaultRtbCampaignManager(),
    protected limitationManager: LimitationManager = new DefaultLimitationManager(),
    protected dmpManager: DmpManager = new DefaultDmpManager(),
    protected creativeManager: CreativeManager = new DefaultCreativeManager(),
    private pmpManager: PmpManager = new DefaultPmpManager()
  ) {
    this.objectType = 'campaign';
    this.order = { ...order };
    this.order.budgetBalance = this.l1Object.budgetBalance;
    this.addonFeatureManager = addonFeatureManager;
    this.manager = manager;
    this.limitationManager = limitationManager;
    this.event = new FireableUpdateEventListener<RtbCampaignSetupFlowPageModel>();
    this.loading = true;
    this.finished = false;
  }

  abstract get type ();

  initWithErrorHandler = async () => {
    try {
      this.showRetry = false;
      await this.init();
    } catch (e) {
      this.showRetry = true;
      this.updateState(false);
    }
  }

  abstract init ();

  abstract getTitle ();

  getCreativeSetupFlowModel = (): CreateCreativeInL2ObjectSetupFlowPageModel | undefined => {
    return undefined;
  }

  protected async initCreatives (campaginId: number) {
    try {
      this.creatives = await this.creativeManager.getCreativesByCampaignId(campaginId, false);
    } catch (e) {}
  }

  async initPmpOfAgency () {
    try {
      this.pmpListOfAgency = await this.pmpManager.getPmpListOfAgency(this.order.agencyId, [PmpStatus.ACTIVE]);
    } catch (e) {}
  }

  async initPmpSpaces () {
    this.updateState(true);
    try {
      if (!this.pmpSpaceOptions) {
        this.pmpSpaceOptions = await this.pmpManager.getPmpSpaces();
      }
    } catch (e) {}
    this.updateState(false);
  }

  async fetchCampaignAnalytics (campaign?: any) {
    this.updateState(true);
    try {
      const campaignData = _.defaultTo(campaign, this.state.campaign);
      const { adType, budget, dailyTargetBudget, optimize, bidPrice, startDate, endDate } = campaignData.basic;
      if (adType === AdType.DISPLAY) {
        this.campaignAnalytics = undefined;
        this.updateState(false);
        return;
      }
      const limitationValue = this.limitationModel.limitationValue;
      await this.campaignBasicFormModel?.fetchRecommendBidPrice(limitationValue);
      const searchKeywords = getLimitationValueContent(limitationValue, OPERATE.INCLUDE, 'searchKeywords');
      const bidPriceRange = this.campaignBasicFormModel?.getBidPriceRange(optimize);
      const suggestPrice = _.get(bidPriceRange, 'recommend.max', _.get(bidPriceRange, 'max', 0));
      const duration = moment(endDate).diff(moment(startDate), 'days') + 1;
      const requestBody = {
        adType,
        lifetimeBudget: budget,
        dailyBudget: (_.isNil(dailyTargetBudget) || dailyTargetBudget.toString() === '0')
          ? (budget / duration)
          : +dailyTargetBudget,
        productIds: campaignData.products ? campaignData.products.map(product => product.productId) : [],
        searchKeywords,
        estimatedClicks: (optimize === L2ObjectOptimizationGoal.CLICKS && bidPrice) ? budget / bidPrice : 0
      };
      this.campaignAnalytics = await this.manager.getCampaignAnalytics(requestBody, this.l1Object.l1ObjectId);
      this.campaignAnalytics.bidPrice = bidPrice >= suggestPrice ? undefined : suggestPrice;
    } catch (e) {}
    this.updateState(false);
  }

  get refreshCampaignAnalyticsEnabled (): boolean {
    const campaignData = this.state.campaign;
    const campaignAnalytics = this.state.campaignAnalytics;
    const isKeywordCampaign = campaignData.basic.adType === AdType.KEYWORD;
    return isKeywordCampaign && _.compact(_.keys(campaignAnalytics)
      .map(fieldName => this.manager.getCampaignAnalyticsHint(campaignAnalytics, fieldName))
    ).length > 0;
  }

  get needSelectProduct () {
    return this.campaignBasicFormModel?.campaignAdType === AdType.KEYWORD;
  }

  get canSelectPmp () {
    return !!this.pmpListOfAgency && this.pmpListOfAgency.length > 0;
  }

  get isPmp () {
    return this.campaignBasicFormModel?.campaignAdType === AdType.PMP;
  }

  to404 () {
    const redirectPath =
      `/orders/${this.order.orderNumber}/campaign-groups/${this.l1Object.l1ObjectId}/campaigns/${this.campaignId}/edit/error404`;
    this.setRedirectPath(redirectPath);
  }

  initBidStrategy () {
    if (!this.campaign) {
      return;
    }

    _.set(this.campaign, 'basic.bidStrategy', BidStrategy.LOWEST_COST_WITH_BID_CAP);
  }

  getRtbCampaignSummaryStepModel (goLast, goStep, goSubStep, subStepIndex: number, creativeSummaryModel?: CreativeSummaryStepModel) {
    if (this.campaignSummaryModel &&
        this.campaignSummaryModel.subStepIndex === subStepIndex &&
        this.campaignSummaryModel.creativeSummaryModel === creativeSummaryModel
    ) {
      return this.campaignSummaryModel;
    }
    this.campaignSummaryModel = new DefaultRtbCampaignSummaryStepModel(this, goLast, goStep, goSubStep, subStepIndex, creativeSummaryModel);
    return this.campaignSummaryModel;
  }

  getLimitationsSummaryData = (limitations): any => {
    const limitationSummaryData = this.manager.getLimitationSummaryData(limitations);
    let summaryData = {
      include: {
        title: i18n.t<string>('campaignSummary.titles.inc'),
        titlePrefixColor: SummaryTitleColor.GREEN,
        content: limitationSummaryData.include
      },
      preferred: {
        title: i18n.t<string>('campaignSummary.titles.preferred'),
        titlePrefixColor: SummaryTitleColor.BLUE,
        content: limitationSummaryData.preferred
      },
      nonPreferred: {
        title: i18n.t<string>('campaignSummary.titles.nonPreferred'),
        titlePrefixColor: SummaryTitleColor.YELLOW,
        content: limitationSummaryData.nonPreferred
      },
      exclude: {
        title: i18n.t<string>('campaignSummary.titles.exc'),
        titlePrefixColor: SummaryTitleColor.RED,
        content: limitationSummaryData.exclude
      },
      other: limitationSummaryData.other ? {
        title: i18n.t<string>('campaignSummary.titles.other'),
        content: limitationSummaryData.other
      } : undefined
    };
    return _.pickBy(summaryData, (item => item && item.content && item.content.length > 0));
  }

  setCampaign = (campaign: any, rerender: boolean | undefined = true) => {
    this.campaign = campaign;
    if (!this.campaignBasicFormModel) {
      this.createCampaignFormModels(campaign.basic.adType);
    }
    rerender && this.updateState(false);
  }

  setPmp = (pmp: Pmp) => {
    this.pmp = pmp;
  }

  setOrder = (order: Order) => {
    this.order = { ...order };
    this.order.budgetBalance = this.l1Object.budgetBalance;
  }

  abstract get campaignId ();

  abstract createCampaignFormModels (adType: AdType): RtbCampaignBasicFormModel | undefined;

  get state (): RtbCampaignSetupFlowPageState {
    return {
      loading: this.loading,
      redirectPath: this.redirectPath,
      campaign: this.campaign,
      finished: this.finished,
      showRetry: this.showRetry,
      pmp: this.pmp,
      pmpSpaceOptions: this.pmpSpaceOptions,
      pmpListOfAgency: this.pmpListOfAgency,
      campaignAnalytics: this.campaignAnalytics
    };
  }

  getCamapignFormModelParams (): RtbCampaignBasicFormModelConstructorParams {
    return [
      this.defaultCampaign,
      this.campaign.basic,
      this.order,
      this.l1Object,
      this.addonFeatureManager,
      this.onPriceModelChange
    ];
  }

  onAdTypeChange (adType: AdType) {
    const campaignBasicModel = this.createCampaignFormModels(adType);
    const defaultCampaign = _.cloneDeep(this.defaultCampaign);
    if (campaignBasicModel) {
      campaignBasicModel.setupDefaultCampaign(defaultCampaign.basic);
    }

    const newCampaign = {
      ...defaultCampaign,
      basic: {
        ...defaultCampaign.basic,
        adType: adType
      }
    };
    this.campaignBasicFormModel = campaignBasicModel;
    if (this.campaignBasicFormModel?.campaignAdType === AdType.PMP) {
      this.setCampaign(newCampaign, false);
      this.initPmpSpaces();
    } else {
      this.setCampaign(newCampaign, true);
    }
  }

  setRedirectPath (redirectPath?: string) {
    this.redirectPath = redirectPath;
    this.updateState(false);
  }

  setFinishedRedirectPath (redirectPath?: string) {
    this.redirectPath = redirectPath;
    this.finished = true;
    this.updateState(false);
  }

  getRtbCampaignBasicFormModel (adType?: AdType): RtbCampaignBasicFormModel | undefined {
    switch (adType) {
      case AdType.DISPLAY:
        this.campaignBasicFormModel = this.campaignDisplayFormModel;
        break;
      case AdType.KEYWORD:
        this.campaignBasicFormModel = this.campaignKeywordFormModel;
        break;
      case AdType.PMP:
        this.campaignBasicFormModel = this.campaignPmpFormModel;
        break;
      default:
        this.campaignBasicFormModel = this.campaignUnknowAdTypeFormModel;
        break;
    }
    return this.campaignBasicFormModel;
  }

  getLimitationModel () {
    const defaultCanNotNull = {};
    const limitationPreSet = this.limitationPreSet ? this.limitationPreSet : {};
    Object.keys(defaultCanNotNull).forEach(opreate => {
      _.remove(defaultCanNotNull[opreate],
        value => !limitationPreSet[opreate] ||
          limitationPreSet[opreate].find(element => element.type === value) === undefined
      );
    });

    const limitationsCanNotNull = _.omitBy(defaultCanNotNull, _.isEmpty);
    const requiredOperateOfTaTypes = {};
    Object.keys(limitationsCanNotNull).forEach(operate => {
      const limitationCanNotNullOfOperate = limitationsCanNotNull[operate];
      limitationCanNotNullOfOperate && limitationCanNotNullOfOperate.forEach(type => {
        let requiredOperateOfTaType = _.defaultTo(requiredOperateOfTaTypes[type], []);
        requiredOperateOfTaType.push(operate);
        requiredOperateOfTaTypes[type] = requiredOperateOfTaType;
      });
    });
    this.limitationModel = this.getNormalLimitationModel(requiredOperateOfTaTypes);
    return this.limitationModel;
  }

  getLimitationByHierarchy (channel: L1ObjectChannel, limitationSetting: any[]): any[] {
    if (_.includes([
      L1ObjectChannel.RTB
    ], channel)) {
      return _.filter(limitationSetting, (inventory) => !['age', 'gender'].includes(inventory.name));
    }
    return limitationSetting;
  }

  getNormalLimitationModel (requiredOperateOfTaTypes) {
    const limitationSetting = [AdType.KEYWORD].includes(this.campaign.basic.adType) ?
      keywordAdsInventorySetting() :
      defaultInventorySetting(requiredOperateOfTaTypes, LIMITATION_TYPE.CAMPAIGN, this.localeMeta);

    const limitationSettingByHierarchy = this.getLimitationByHierarchy(this.l1Object.channel, limitationSetting);
    const basicModel = this.getRtbCampaignBasicFormModel(this.campaign.basic.adType);
    const limitationOperates = _.get(basicModel, 'limitationOperates', {
      need: [],
      notNeed: []
    });
    if (!this.normalLimitationModel) {
      this.normalLimitationModel = new DefaultEditLimitationModel(
        limitationSettingByHierarchy,
        this.campaign.limitations,
        limitationOperates,
        this.addonFeatureManager.addonFeature
      );
      if ([AdType.KEYWORD].includes(this.campaign.basic.adType)) {
        this.normalLimitationModel.showInventory('searchKeywords', 'include');
      }
    }
    this.normalLimitationModel.setLimitationSetting(limitationSettingByHierarchy, limitationOperates);
    return this.normalLimitationModel;
  }

  onPriceModelChange = (priceModel, currentCampaignBasic) => {
    if (!this.campaignBasicFormModel) {
      return;
    }

    const limitationModel = this.getLimitationModel();
    const optimize = this.campaignBasicFormModel.getDefaultOptimizeType(priceModel);
    this.setCampaign({
      basic: {
        ...currentCampaignBasic,
        priceModel,
        optimize,
        orderPrice: undefined,
        bidPrice: undefined,
        bidStrategy: BidStrategy.LOWEST_COST_WITH_BID_CAP,
        frequency: undefined
      },
      limitations: limitationModel.limitationValue
    });
  }

  cancel = () => {
    const redirectPath = `/orders/${this.order.orderNumber}/campaign-groups/${this.l1Object.l1ObjectId}`;
    this.setRedirectPath(redirectPath);
  }

  validateCampaign () {
    return this.campaign.basic.groupId !== _.get(this.l1Object, 'rtb.group_id') || this.campaign.basic.state === CampaignState.DELETE;
  }

  onUnmount (handler) {
    this.event.remove(handler);
    this.redirectPath = undefined;
    this.campaign = undefined;
    this.rbLimitationModel = undefined;
    this.normalLimitationModel = undefined;
    this.campaignSummaryModel = undefined;
    this.finished = false;
  }

  updateState (loading: boolean) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}

abstract class CreateFlowPageModel extends DefaultRtbCampaignSetupFlowPageModel {

  createCampaignFormModels (adType: AdType) {
    const params = this.getCamapignFormModelParams();
    this.campaignDisplayFormModel = new CreateDisplayFormModel(...params);
    this.campaignUnknowAdTypeFormModel = new CreateUnknowAdTypeFormModel(...params);
    this.campaignKeywordFormModel = new CreateKeywordFormModel(...params);
    this.campaignPmpFormModel = new CreatePmpFormModel(...params);
    return this.getRtbCampaignBasicFormModel(adType);
  }

  addCreative = (campaignId: number) => {
    let path = `/creatives/new?campaignId=${campaignId}&orderNumber=${this.order.orderNumber}&l1ObjectId=${this.l1Object.l1ObjectId}&channel=${this.l1Object.channel}`;
    this.setFinishedRedirectPath(path);
  }
}

abstract class CreateFlowFromExistOnePageModel extends CreateFlowPageModel {

  constructor (
    protected modalCampaignId: number,
    ...args: ConstructorParameters<typeof DefaultRtbCampaignSetupFlowPageModel>
  ) {
    super(...args);
    this.modalCampaignId = modalCampaignId;
  }
}

abstract class EditFlowPageModel extends DefaultRtbCampaignSetupFlowPageModel {

  constructor (
    protected modalCampaignId: number,
    ...args: ConstructorParameters<typeof DefaultRtbCampaignSetupFlowPageModel>
  ) {
    super(...args);
    this.modalCampaignId = modalCampaignId;
  }

  createCampaignFormModels (adType: AdType) {
    const params = this.getCamapignFormModelParams();
    this.campaignDisplayFormModel = new EditDisplayFormModel(...params);
    this.campaignUnknowAdTypeFormModel = new EditUnknowAdTypeFormModel(...params);
    this.campaignKeywordFormModel = new EditKeywordFormModel(...params);
    this.campaignPmpFormModel = new EditPmpFormModel(...params);
    return this.getRtbCampaignBasicFormModel(adType);
  }
}

export class CreateRtbCampaignSetupFlowPageModel extends CreateFlowPageModel {

  creativeCreateFlowModel?: CreateCreativeInL2ObjectSetupFlowPageModel;
  creativeCreateEventHandler?: number;

  get type () {
    return 'create';
  }

  get campaignId () {
    return null;
  }

  async init () {
    this.updateState(true);

    await this.initPmpOfAgency();
    this.limitationPreSet = await this.limitationManager.getLimitationPreSet('campaign');
    this.campaign = await this.createDefaultCampaign();
    this.initBidStrategy();
    this.defaultCampaign = _.cloneDeep(this.campaign);
    this.createCampaignFormModels(this.campaign.basic.adType);

    this.updateState(false);
  }

  getTitle () {
    return i18n.t<string>('campaign.labels.createCampaignTitle');
  }

  async createDefaultCampaign () {
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    const ageLimitationPresets = [{
      type: 'age_min',
      value: RTBCAMPAIGN_DEFAULT_AGE_MIN
    }, {
      type: 'age_max',
      value: RTBCAMPAIGN_DEFAULT_AGE_MAX
    }];
    const genderLimitationPresets = [];
    const preferredLimitationPresets = _.defaultTo(this.limitationPreSet.preferred, []);
    const limitationPreSet = {
      ...this.limitationPreSet,
      preferred: [
        ...preferredLimitationPresets,
        ...ageLimitationPresets,
        ...genderLimitationPresets
      ]
    };
    const startDate = this.getCampaignStartDay();
    const endDate = this.getCampaignEndDay();
    const parentBidStrategy = _.get(this.l1Object, 'rtb.bid_strategy');
    const campaign = {
      basic: {
        advertiserId: this.order.advertiserId,
        budget: this.order.budgetBalance,
        checkpoints: [],
        creativeDeliverType: CreativeDeliverType.OPTIMIZE,
        dailyTargetBudget: null,
        tags: [],
        name: this.l1Object.name,
        orderId: this.order.id,
        startDate: startDate.format(dateFormat),
        endDate: endDate.format(dateFormat),
        priceModel: RtbCampaignPlanType.RS,
        optimize: L2ObjectOptimizationGoal.CLICKS,
        deliverType: DeliverType.STANDARD,
        expectedSpent: 0,
        bidPrice: parentBidStrategy && parentBidStrategy === BidStrategy.LOWEST_COST_WITH_BID_CAP ? 0 : undefined
      },
      limitations: _.cloneDeep(limitationPreSet)
    };

    return campaign;
  }

  getCampaignStartDay () {
    const now = moment()
      .startOf('hour');
    const orderStartDate = moment(this.order.startDate);
    return now.isBefore(orderStartDate) ? orderStartDate : now;
  }

  getCampaignEndDay () {
    return moment.min(
      moment(this.order.endDate)
        .add(1, 'days')
        .subtract(1, 'seconds'),
      this.getCampaignStartDay().add(91, 'days').endOf('day')
    );
  }

  onAdTypeChange (adType: AdType): void {
    super.onAdTypeChange(adType);
    this.creativeCreateFlowModel = undefined;
    this.normalLimitationModel = undefined;
  }

  getCreativeSetupFlowModel = () => {
    if (!this.isPmp) {
      return undefined;
    }
    if (
      this.creativeCreateFlowModel &&
      this.creativeCreateFlowModel.campaign.basic.adType === this.campaign.basic.adType
    ) {
      this.creativeCreateFlowModel.creative && this.creativeCreateFlowModel.setCreative({
        ...this.creativeCreateFlowModel.creative,
        basic: {
          ...this.creativeCreateFlowModel.creative.basic,
          name: this.campaign.basic.name
        }
      });
      return this.creativeCreateFlowModel;
    }
    this.creativeCreateFlowModel = new CreateCreativeInL2ObjectSetupFlowPageModel(false, [], this.addonFeatureManager, this.campaign, this.order);
    this.creativeCreateEventHandler = this.creativeCreateFlowModel.event.add(() => {});
    this.creativeCreateFlowModel.init();
    return this.creativeCreateFlowModel;
  }

  onUnmount (handler) {
    super.onUnmount(handler);
    if (this.creativeCreateFlowModel) {
      this.creativeCreateFlowModel.onUnmount(this.creativeCreateEventHandler);
      this.creativeCreateFlowModel = undefined;
    }
  }
}

export class EditRtbCampaignSetupFlowPageModel extends EditFlowPageModel {

  get type () {
    return 'edit';
  }

  get campaignId () {
    return this.modalCampaignId;
  }

  async getCampaign () {
    return this.manager.getCampaign(this.modalCampaignId);
  }

  async init () {
    this.updateState(true);

    await this.initPmpOfAgency();
    this.limitationPreSet = await this.limitationManager.getLimitationPreSet('campaign');
    let campaign = await this.getCampaign();
    const campaignId = campaign.basic.id;
    if (campaignId) {
      await this.initCreatives(campaignId);
      campaign = {
        ...campaign,
        products: this.creatives ?
          _.compact(this.creatives.filter(creative => creative.bindingState !== CampaignBannerMapState.DISABLE).map(creative => creative.product)) :
          undefined
      };
    }
    this.campaign = campaign;
    this.initBidStrategy();
    this.defaultCampaign = _.cloneDeep(this.campaign);
    this.createCampaignFormModels(this.campaign.basic.adType);
    if (this.validateCampaign()) {
      this.to404();
      return;
    }
    this.campaignBasicFormModel = this.getRtbCampaignBasicFormModel(this.campaign.basic.adType);
    this.updateState(false);
  }

  getTitle () {
    return i18n.t<string>('campaign.labels.editCampaignTitle');
  }
}

export class CopyRtbCampaignSetupFlowPageModel extends CreateFlowFromExistOnePageModel {

  get type () {
    return 'copy';
  }

  get campaignId () {
    return this.modalCampaignId;
  }

  async init () {
    this.updateState(true);

    await this.initPmpOfAgency();
    this.limitationPreSet = await this.limitationManager.getLimitationPreSet('campaign');
    this.campaign = await this.manager.getNoCidCampaign(this.campaignId);
    this.initBidStrategy();
    this.campaign.basic.spents = 0;
    this.campaign.basic.expectedSpent = 0;
    const campaignIsStart = moment().isAfter(this.campaign.basic.startDate);
    const campaignIsEnd = moment().isAfter(this.campaign.basic.endDate);
    if (campaignIsStart) {
      this.campaign.basic.startDate = moment().startOf('hours').format('YYYY-MM-DD HH:mm:ss');
    }
    if (campaignIsEnd) {
      this.campaign.basic.endDate = moment(this.order.endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss');
    }
    this.defaultCampaign = _.cloneDeep(this.campaign);
    this.createCampaignFormModels(this.campaign.basic.adType);
    if (this.validateCampaign()) {
      this.to404();
      return;
    }
    this.updateState(false);
  }

  getTitle () {
    return i18n.t<string>('campaign.labels.copyCampaignTitle');
  }
}

export class SplitRtbCampaignSetupFlowPageModel extends CreateFlowFromExistOnePageModel {

  get type () {
    return 'split';
  }

  get campaignId (): number | null {
    return this.modalCampaignId;
  }

  async init () {
    this.updateState(true);

    await this.initPmpOfAgency();
    this.limitationPreSet = await this.limitationManager.getLimitationPreSet('campaign');
    this.campaign = await this.manager.getNoCidCampaign(this.modalCampaignId);
    this.initBidStrategy();
    this.campaign.basic.expectedSpent = 0;
    this.campaign.basic.spents = 0;
    this.campaign.basic.state = CampaignState.ACTIVATE;
    const campaignIsStart = moment().isAfter(this.campaign.basic.startDate);
    const campaignIsEnd = moment().isAfter(this.campaign.basic.endDate);
    if (campaignIsStart) {
      this.campaign.basic.startDate = moment().startOf('hours').format('YYYY-MM-DD HH:mm:ss');
    }
    if (campaignIsEnd) {
      this.campaign.basic.endDate = moment(this.order.endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss');
    }
    const oldCampaignSpent = this.campaign.basic.state !== CampaignState.DEACTIVATE ? this.campaign.basic.expectedSpent : this.campaign.basic.spents;
    const minBudgetOfCampaign = this.manager.getMinBudgetOfCampaign(this.campaign.basic, this.order.campaignConstraint.budgetMinimum);
    const budgetMinimum = this.campaign.basic.state !== CampaignState.DEACTIVATE ? minBudgetOfCampaign : 0;
    let oldCampaignMin = oldCampaignSpent > budgetMinimum ? oldCampaignSpent : budgetMinimum;
    if (this.campaign.basic.state === CampaignState.DEACTIVATE) {
      oldCampaignMin = this.campaign.basic.spents;
    }
    this.order.budgetBalance = oldCampaignSpent > this.campaign.basic.budget ? 0 : getPriceValue(this.order.currency, this.campaign.basic.budget - oldCampaignMin);
    this.campaign.basic.budget = this.order.budgetBalance;
    const includeTA = this.campaign.limitations.include;
    if (includeTA) {
      this.campaign.limitations.include = includeTA.filter(limitation => limitation.type !== 'searchKeywords');
    }
    this.defaultCampaign = _.cloneDeep(this.campaign);
    this.createCampaignFormModels(this.campaign.basic.adType);
    if (this.validateCampaign()) {
      this.to404();
      return;
    }

    this.updateState(false);
  }

  getTitle () {
    return i18n.t<string>('campaign.labels.splitCampaignTitle');
  }
}
