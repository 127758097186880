import { commaDecorator, percentDecorator, fractionDecorator, totalCostDecorator, divideValue, costDecorator } from './ReportDataHelper';
import { getReportTableFormatters } from './ReportTableFormatters';
import { ECConvDays, ReportDimension, ReportTableData } from 'core/report/ReportData';
import { ColumnDefinition, headerWithIconFormatter, renderColumn } from 'components/TableColumn/TableColumn';
import styles from './reportTable.module.scss';
import i18n from 'i18n';
import _ from 'lodash';
import { SelectOptions } from 'components/commonType';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';

export enum REPORT_COLUMNS {
  NAME = 'name',
  IMPRES = 'impres',
  CLICKS = 'clicks',
  CTR = 'ctr',
  CPM = 'cpm',
  CPC = 'cpc',
  MEDIA_COST = 'mediaCost',
  MEDIA_SPENT = 'mediaSpent',
  SPENT = 'spent',
  TAGS = 'tags',
  DIRECT_ADD_TO_CART = 'directAddToCart',
  DIRECT_ORDER = 'directOrder',
  DIRECT_PRODUCT_SALES_COUNT = 'directProductSalesCount',
  DIRECT_PRODUCT_SALES_AMOUNT = 'directProductSalesAmount',
  DIRECT_CONVERSION_RATE = 'directConversionRate',
  DIRECT_ROAS = 'directRoas',
  INDIRECT_ADD_TO_CART = 'indirectAddToCart',
  INDIRECT_ORDER = 'indirectOrder',
  INDIRECT_PRODUCT_SALES_COUNT = 'indirectProductSalesCount',
  INDIRECT_PRODUCT_SALES_AMOUNT = 'indirectProductSalesAmount',
  INDIRECT_CONVERSION_RATE = 'indirectConversionRate',
  INDIRECT_ROAS = 'indirectRoas',
  EDITBTNS = 'editBtns'
}

export const tableColumnSettings = (
  tableData: ReportTableData[],
  dimension: ReportDimension,
  currency: string,
  onDimensionNameClick: (dimension: string, rowNameValue: SelectOptions) => void,
  onDateClick?: (date: string) => void,
  ecConvDays?: ECConvDays
) => {

  const getColumnValueOfReportRecord = (record: ReportTableData, column: REPORT_COLUMNS): number => {
    const value = _.get(record, column, 0);
    if (typeof value !== 'number') {
      return 0;
    }
    return value;
  };

  const getCostSumFooterValue = (column: REPORT_COLUMNS): number => {
    const columnData = tableData.map((data) => getColumnValueOfReportRecord(data, column));
    return columnData.reduce((acc, item) => acc + _.floor(item, 2), 0);
  };

  const getSumFooterValue = (column: REPORT_COLUMNS): number => {
    const columnData = tableData.map((data) => getColumnValueOfReportRecord(data, column));
    return columnData.reduce((acc, item) => acc + item, 0);
  };

  const getFractionFooterValue = (
    numeratorColumn: REPORT_COLUMNS,
    denominatorColumn: REPORT_COLUMNS,
    multiply: number,
    decorator?: (value: number) => number
  ): number => {
    const numeratorData = tableData.map((data) =>
      getColumnValueOfReportRecord(data, numeratorColumn)
    );
    const denominatorData = tableData.map((data) =>
      getColumnValueOfReportRecord(data, denominatorColumn)
    );
    const sumOfNumerator = numeratorData.reduce((acc, item) => acc + item, 0);
    const sumOfDenominator = denominatorData.reduce(
      (acc, item) => acc + item,
      0
    );
    if (sumOfDenominator === 0) {
      return 0;
    }
    return divideValue(sumOfNumerator * multiply, sumOfDenominator, decorator);
  };

  const getCostFractionFooterValue = (
    numeratorColumn: REPORT_COLUMNS,
    denominatorColumn: REPORT_COLUMNS,
    multiply: number,
    decorator?: (value: number) => number
  ) => {
    const numeratorData = tableData.map((data) =>
      getColumnValueOfReportRecord(data, numeratorColumn)
    );
    const denominatorData = tableData.map((data) =>
      getColumnValueOfReportRecord(data, denominatorColumn)
    );
    const sumOfNumerator = numeratorData.reduce(
      (acc, item) => acc + _.floor(item, 2),
      0
    );
    const sumOfDenominator = denominatorData.reduce(
      (acc, item) => acc + _.floor(item, 2),
      0
    );
    if (sumOfDenominator === 0) {
      return 0;
    }
    return divideValue(sumOfNumerator * multiply, sumOfDenominator, decorator);
  };

  const getColumnSetting = (
    column: REPORT_COLUMNS,
    formatter,
    footer: number | string,
    textDecorator: (data: number) => string,
    footerDecorator?: (data: number) => string,
    currency?: string,
    hint?: string
  ): ColumnDefinition => {
    let headerName: string = column;
    if (column === REPORT_COLUMNS.NAME) {
      if (
        dimension === ReportDimension.MONTH ||
        dimension === ReportDimension.DAY ||
        dimension === ReportDimension.HOUR
      ) {
        headerName = 'date';
      } else if (dimension === ReportDimension.PRODUCT) {
        headerName = 'product';
      } else if (dimension === ReportDimension.SEARCH_KEYWORD) {
        headerName = 'searchKeyword';
      }
    }
    const columnClassGetter = () => {
      return `${styles.reportCell} ${styles[column]}`;
    };
    const hintExtraData = hint
      ? {
        hint,
        icon: faQuestionCircle,
        className: styles.tableHeaderHint
      }
      : {};
    return renderColumn(
      {
        sort: true,
        text: i18n.t(`reportTable.headers.${headerName}`, { currency }),
        dataField: column,
        footer:
          typeof footer === 'string'
            ? footer
            : footerDecorator
              ? footerDecorator(footer)
              : textDecorator(footer),
        formatExtraData: {
          dimension,
          textDecorator,
          ...hintExtraData
        },
        classes: columnClassGetter,
        headerClasses: columnClassGetter,
        footerClasses: columnClassGetter
      },
      formatter,
      hint ? headerWithIconFormatter : undefined
    );
  };
  const formatters = getReportTableFormatters(
    onDimensionNameClick,
    onDateClick
  );
  const convHintSuffix = _.capitalize(ecConvDays?.toString());
  return {
    [REPORT_COLUMNS.NAME]: getColumnSetting(
      REPORT_COLUMNS.NAME,
      formatters.nameFormatter,
      i18n.t<string>('reportTable.footers.total'),
      _.identity
    ),
    [REPORT_COLUMNS.IMPRES]: getColumnSetting(
      REPORT_COLUMNS.IMPRES,
      formatters.textFormatter,
      getSumFooterValue(REPORT_COLUMNS.IMPRES),
      commaDecorator
    ),
    [REPORT_COLUMNS.CLICKS]: getColumnSetting(
      REPORT_COLUMNS.CLICKS,
      formatters.textFormatter,
      getSumFooterValue(REPORT_COLUMNS.CLICKS),
      commaDecorator
    ),
    [REPORT_COLUMNS.CTR]: getColumnSetting(
      REPORT_COLUMNS.CTR,
      formatters.textFormatter,
      getFractionFooterValue(REPORT_COLUMNS.CLICKS, REPORT_COLUMNS.IMPRES, 100),
      percentDecorator,
      undefined,
      undefined,
      i18n.t<string>(`reportTable.headers.hints.${REPORT_COLUMNS.CTR}`)
    ),
    [REPORT_COLUMNS.CPM]: getColumnSetting(
      REPORT_COLUMNS.CPM,
      formatters.textFormatter,
      getCostFractionFooterValue(
        REPORT_COLUMNS.SPENT,
        REPORT_COLUMNS.IMPRES,
        1000
      ),
      fractionDecorator,
      undefined,
      undefined,
      i18n.t<string>(`reportTable.headers.hints.${REPORT_COLUMNS.CPM}`)
    ),
    [REPORT_COLUMNS.CPC]: getColumnSetting(
      REPORT_COLUMNS.CPC,
      formatters.textFormatter,
      getCostFractionFooterValue(
        REPORT_COLUMNS.SPENT,
        REPORT_COLUMNS.CLICKS,
        1
      ),
      fractionDecorator,
      undefined,
      undefined,
      i18n.t<string>(`reportTable.headers.hints.${REPORT_COLUMNS.CPC}`)
    ),
    [REPORT_COLUMNS.MEDIA_COST]: getColumnSetting(
      REPORT_COLUMNS.MEDIA_COST,
      formatters.textFormatter,
      getCostSumFooterValue(REPORT_COLUMNS.MEDIA_COST),
      costDecorator,
      _.curry(totalCostDecorator)(currency),
      currency
    ),
    [REPORT_COLUMNS.MEDIA_SPENT]: getColumnSetting(
      REPORT_COLUMNS.MEDIA_SPENT,
      formatters.textFormatter,
      getCostSumFooterValue(REPORT_COLUMNS.MEDIA_SPENT),
      costDecorator,
      _.curry(totalCostDecorator)(currency),
      currency
    ),
    [REPORT_COLUMNS.SPENT]: getColumnSetting(
      REPORT_COLUMNS.SPENT,
      formatters.textFormatter,
      getCostSumFooterValue(REPORT_COLUMNS.SPENT),
      costDecorator,
      _.curry(totalCostDecorator)(currency),
      currency
    ),
    [REPORT_COLUMNS.TAGS]: getColumnSetting(
      REPORT_COLUMNS.TAGS,
      formatters.tagFormatter,
      '',
      _.identity
    ),
    [REPORT_COLUMNS.EDITBTNS]: {
      ...getColumnSetting(
        REPORT_COLUMNS.EDITBTNS,
        formatters.floatingEditBtnsFormatter,
        '',
        _.identity
      ),
      text: '',
      sort: false
    },
    [REPORT_COLUMNS.DIRECT_ADD_TO_CART]: getColumnSetting(
      REPORT_COLUMNS.DIRECT_ADD_TO_CART,
      formatters.textFormatter,
      getSumFooterValue(REPORT_COLUMNS.DIRECT_ADD_TO_CART),
      commaDecorator,
      undefined,
      undefined,
      i18n.t<string>(`reportTable.headers.hints.${REPORT_COLUMNS.DIRECT_ADD_TO_CART}${convHintSuffix}`)
    ),
    [REPORT_COLUMNS.DIRECT_ORDER]: getColumnSetting(
      REPORT_COLUMNS.DIRECT_ORDER,
      formatters.textFormatter,
      getSumFooterValue(REPORT_COLUMNS.DIRECT_ORDER),
      commaDecorator,
      undefined,
      undefined,
      i18n.t<string>(`reportTable.headers.hints.${REPORT_COLUMNS.DIRECT_ORDER}${convHintSuffix}`)
    ),
    [REPORT_COLUMNS.DIRECT_PRODUCT_SALES_COUNT]: getColumnSetting(
      REPORT_COLUMNS.DIRECT_PRODUCT_SALES_COUNT,
      formatters.textFormatter,
      getSumFooterValue(REPORT_COLUMNS.DIRECT_PRODUCT_SALES_COUNT),
      commaDecorator,
      undefined,
      undefined,
      i18n.t<string>(`reportTable.headers.hints.${REPORT_COLUMNS.DIRECT_PRODUCT_SALES_COUNT}${convHintSuffix}`)
    ),
    [REPORT_COLUMNS.DIRECT_PRODUCT_SALES_AMOUNT]: getColumnSetting(
      REPORT_COLUMNS.DIRECT_PRODUCT_SALES_AMOUNT,
      formatters.textFormatter,
      getSumFooterValue(REPORT_COLUMNS.DIRECT_PRODUCT_SALES_AMOUNT),
      costDecorator,
      undefined,
      currency,
      i18n.t<string>(`reportTable.headers.hints.${REPORT_COLUMNS.DIRECT_PRODUCT_SALES_AMOUNT}${convHintSuffix}`)
    ),
    [REPORT_COLUMNS.INDIRECT_ADD_TO_CART]: getColumnSetting(
      REPORT_COLUMNS.INDIRECT_ADD_TO_CART,
      formatters.textFormatter,
      getSumFooterValue(REPORT_COLUMNS.INDIRECT_ADD_TO_CART),
      commaDecorator,
      undefined,
      undefined,
      i18n.t<string>(`reportTable.headers.hints.${REPORT_COLUMNS.INDIRECT_ADD_TO_CART}${convHintSuffix}`)
    ),
    [REPORT_COLUMNS.INDIRECT_ORDER]: getColumnSetting(
      REPORT_COLUMNS.INDIRECT_ORDER,
      formatters.textFormatter,
      getSumFooterValue(REPORT_COLUMNS.INDIRECT_ORDER),
      commaDecorator,
      undefined,
      undefined,
      i18n.t<string>(`reportTable.headers.hints.${REPORT_COLUMNS.INDIRECT_ORDER}${convHintSuffix}`)
    ),
    [REPORT_COLUMNS.INDIRECT_PRODUCT_SALES_COUNT]: getColumnSetting(
      REPORT_COLUMNS.INDIRECT_PRODUCT_SALES_COUNT,
      formatters.textFormatter,
      getSumFooterValue(REPORT_COLUMNS.INDIRECT_PRODUCT_SALES_COUNT),
      commaDecorator,
      undefined,
      undefined,
      i18n.t<string>(`reportTable.headers.hints.${REPORT_COLUMNS.INDIRECT_PRODUCT_SALES_COUNT}${convHintSuffix}`)
    ),
    [REPORT_COLUMNS.INDIRECT_PRODUCT_SALES_AMOUNT]: getColumnSetting(
      REPORT_COLUMNS.INDIRECT_PRODUCT_SALES_AMOUNT,
      formatters.textFormatter,
      getSumFooterValue(REPORT_COLUMNS.INDIRECT_PRODUCT_SALES_AMOUNT),
      costDecorator,
      undefined,
      currency,
      i18n.t<string>(`reportTable.headers.hints.${REPORT_COLUMNS.INDIRECT_PRODUCT_SALES_AMOUNT}${convHintSuffix}`)
    ),
    [REPORT_COLUMNS.DIRECT_CONVERSION_RATE]: getColumnSetting(
      REPORT_COLUMNS.DIRECT_CONVERSION_RATE,
      formatters.textFormatter,
      getFractionFooterValue(
        REPORT_COLUMNS.DIRECT_PRODUCT_SALES_COUNT,
        REPORT_COLUMNS.CLICKS,
        100,
        (value) => _.round(value, 2)
      ),
      percentDecorator,
      undefined,
      undefined,
      i18n.t<string>(`reportTable.headers.hints.${REPORT_COLUMNS.DIRECT_CONVERSION_RATE}`)
    ),
    [REPORT_COLUMNS.DIRECT_ROAS]: getColumnSetting(
      REPORT_COLUMNS.DIRECT_ROAS,
      formatters.textFormatter,
      getCostFractionFooterValue(
        REPORT_COLUMNS.DIRECT_PRODUCT_SALES_AMOUNT,
        REPORT_COLUMNS.SPENT,
        1,
        (value) => _.round(value, 2)
      ),
      fractionDecorator,
      undefined,
      undefined,
      i18n.t<string>(`reportTable.headers.hints.${REPORT_COLUMNS.DIRECT_ROAS}`)
    ),
    [REPORT_COLUMNS.INDIRECT_CONVERSION_RATE]: getColumnSetting(
      REPORT_COLUMNS.INDIRECT_CONVERSION_RATE,
      formatters.textFormatter,
      getFractionFooterValue(
        REPORT_COLUMNS.INDIRECT_PRODUCT_SALES_COUNT,
        REPORT_COLUMNS.CLICKS,
        100,
        (value) => _.round(value, 2)
      ),
      percentDecorator,
      undefined,
      undefined,
      i18n.t<string>(`reportTable.headers.hints.${REPORT_COLUMNS.INDIRECT_CONVERSION_RATE}`)
    ),
    [REPORT_COLUMNS.INDIRECT_ROAS]: getColumnSetting(
      REPORT_COLUMNS.INDIRECT_ROAS,
      formatters.textFormatter,
      getCostFractionFooterValue(
        REPORT_COLUMNS.INDIRECT_PRODUCT_SALES_AMOUNT,
        REPORT_COLUMNS.SPENT,
        1,
        (value) => _.round(value, 2)
      ),
      fractionDecorator,
      undefined,
      undefined,
      i18n.t<string>(`reportTable.headers.hints.${REPORT_COLUMNS.INDIRECT_ROAS}`)
    )
  };
};
