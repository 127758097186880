import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import i18n from 'i18n';
import { RtbCampaignSetupFlowPageModel } from '../RtbCampaignSetupFlowPageModel';
import styles from './chooseProductsStep.module.scss';
import { ProductListDataContext } from 'containers/RetailRMN/Products/ProductListDataContext';
import { Product } from 'core/product/Product';
import { ProductHome } from 'containers/RetailRMN/Products/ProductHome';
import { defaultTo, get, isNil, omitBy, uniq, xor } from 'lodash';
import { Modal } from 'components/Modal/Modal';

export type BindProduct = {
  productId: string;
  name: string;
  category: string;
  imgLink: string;
};

export type ChooseProductsStepProps = {
  goNext: () => void;
  goLast: () => void;
  lockRemainSteps: () => void;
  flowModel: RtbCampaignSetupFlowPageModel;
  bindProducts?: Product[];
};

export const ChooseProductsStep: React.FunctionComponent<ChooseProductsStepProps> = ({
  goNext,
  goLast,
  lockRemainSteps,
  flowModel
}) => {

  const [selectedProducts, setSelectedProducts] = useState<Product[]>(defaultTo(flowModel.state.campaign.products, []));
  const [showResetKeywordsAlert, setShowResetKeywordsAlert] = useState<boolean>(false);

  useEffect(() => {
    lockRemainSteps();
  }, [lockRemainSteps]);

  const hasSetupKeywordTA = useMemo(() => {
    const limitationModel = flowModel.limitationModel;
    const keywordTAError = limitationModel ? get(limitationModel.state.errors, 'include.searchKeywords') : undefined;
    const includeTA = get(flowModel.state.campaign.limitations, 'include', []);
    const searchKeywordsTA = includeTA.find(ta => ta.type === 'searchKeywords');
    return (!!searchKeywordsTA && searchKeywordsTA.value.length > 0) || !!keywordTAError;
  }, [flowModel.limitationModel, flowModel.state.campaign]);

  const needUpdateProducts = useMemo(() => {
    const oriProducts = defaultTo(flowModel.state.campaign.products, []);
    const oriProductIds = uniq(oriProducts.map(p => p.productId));
    const selectedProductIds = uniq(selectedProducts.map(p => p.productId));
    return xor(oriProductIds, selectedProductIds).length > 0;
  }, [flowModel.state.campaign.products, selectedProducts]);

  const needResetKeywords = useMemo(() => {
    return hasSetupKeywordTA && needUpdateProducts;
  }, [needUpdateProducts, hasSetupKeywordTA]);

  const updateCampaignAndGoNext = useCallback(() => {
    const campaign = flowModel.state.campaign;
    const limitationModel = flowModel.limitationModel;
    const includeTA = campaign.limitations.include;
    limitationModel && limitationModel.clearLimitation('include', 'searchKeywords', true);
    flowModel.setCampaign({
      ...campaign,
      products: selectedProducts,
      limitations: omitBy({
        ...campaign.limitations,
        include: includeTA ? includeTA.filter(limitation => limitation.type !== 'searchKeywords') : undefined
      }, isNil)
    });
    setShowResetKeywordsAlert(false);
    goNext();
  }, [goNext, flowModel, selectedProducts]);

  const onGoNext = useCallback(() => {
    if (needResetKeywords) {
      setShowResetKeywordsAlert(true);
      return;
    }
    if (needUpdateProducts) {
      const campaign = flowModel.state.campaign;
      flowModel.setCampaign({
        ...campaign,
        products: selectedProducts
      });
    }
    goNext();
  }, [needResetKeywords, flowModel, selectedProducts, needUpdateProducts, goNext, setShowResetKeywordsAlert]);

  const closeGoNextAlert = useCallback(() => {
    setShowResetKeywordsAlert(false);
  }, []);

  return (
    <div className={styles.chooseProductsStep}>
      <div className={styles.productList}>
        {showResetKeywordsAlert &&
          <Modal
            title={i18n.t<string>('common.warning')}
            primaryButton={{
              title: i18n.t<string>('common.labels.yes'),
              callback: updateCampaignAndGoNext
            }}
            secondaryButton={{
              title: i18n.t<string>('common.labels.no'),
              callback: goNext
            }}
            dismiss={closeGoNextAlert}
          >
            {i18n.t<string>('campaign.messages.clearSearchKeywords')}
          </Modal>
        }
        <ProductListDataContext.Provider
          value={{
            selectedProducts,
            handleSelectedProducts: (fn: (selectedProducts: Product[]) => Product[]): void => {
              setSelectedProducts(fn(selectedProducts));
            },
            handleFilterChanged: () => {}
          }}
        >
          <ProductHome
            defaultFilters={{
              advertiser: flowModel.order.advertiserId
            }}
            title={i18n.t<string>('campaign.labels.selectProduct')}
          />
        </ProductListDataContext.Provider>
      </div>
      <div className={styles.buttonArea}>
        {selectedProducts.length === 0 ?
          <OverlayTrigger
            placement={'right'}
            trigger={['hover', 'focus']}
            overlay={
              <Tooltip id={`onGoNext-tooltip`}>
                {i18n.t<string>('campaign.messages.productCannotEmpty')}
              </Tooltip>
            }
          >
            <Button variant='primary' size='sm' onClick={onGoNext} disabled>
              {i18n.t<string>('campaign.buttons.completeAndCheck')}
            </Button>
          </OverlayTrigger> :
          <Button variant='primary' size='sm' onClick={onGoNext}>
            {i18n.t<string>('campaign.buttons.completeAndCheck')}
          </Button>
        }
        {flowModel.type === 'create' && <Button variant='secondary' size='sm' onClick={goLast}>
          {i18n.t<string>('campaign.buttons.back')}
        </Button>}
      </div>
    </div>
  );
};
