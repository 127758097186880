import React from 'react';
import styles from './defaultSearchKeywordsComponent.module.scss';
import i18n from 'i18n';
import smallAddIcon from 'assets/add-small.svg';
import smallAddDisabledIcon from 'assets/add-small-disabled.svg';
import { partial, defaultTo } from 'lodash';
import classnames from 'classnames/bind';
import { getNumberRangeIndexHint } from 'utils/StringUtil';

const cx = classnames.bind(styles);

const nameHeaderFormatter = (
  onClickAllButtonEnabled: boolean,
  onClickAll,
  column: any,
  _1,
  { sortElement }
) => {
  const addAllIconClassName = cx(styles.addAllIcon, {
    disabled: !onClickAllButtonEnabled
  });
  const addAllIconSrc = onClickAllButtonEnabled ? smallAddIcon : smallAddDisabledIcon;
  return (
    <div className={styles.nameHeader}>
      <div className={styles.selectClickBox}>
        <img
          className={addAllIconClassName}
          src={addAllIconSrc}
          alt='addAll'
          onClick={onClickAll}
        />
      </div>
      {i18n.t<string>(column.text)}
      {sortElement}
    </div>
  );
};

const nameFormatter = (suggestKeyword: any, _1, _2, formatExtraData: any): any => {
  const { onClick } = formatExtraData;

  return (
    <div className={styles.nameCell}>
      <div className={styles.selectClickBox}>
        <img
          className={styles.addIcon}
          src={smallAddIcon}
          alt='add'
          onClick={partial(onClick, suggestKeyword)}
        />
      </div>
      <div className={styles.info}>
        <div className={styles.name}>
          {suggestKeyword}
        </div>
      </div>
    </div>
  );
};

const bidPriceFormatter = (bidPrice: any) => {
  return (
    <div className={styles.bidPriceCell}>
      {`${bidPrice} ${i18n.t<string>('suggestKeywordsList.labels.postfix.price')}`}
    </div>
  );
};

const keywordSearchVolumeFormatter = (keywordSearchVolume: any) => {
  const request = defaultTo(keywordSearchVolume, 0);
  const value = getNumberRangeIndexHint(request, 500, 10000);
  return (
    <div className={styles.keywordSearchVolumeCell}>
      {value}
    </div>
  );
};

const growthRateFormatter = (growthRate: any) => {
  const value = growthRate ? (growthRate * 100).toFixed(2) + '%' : '-';
  return (
    <div className={styles.growthRateCell}>
      {value}
    </div>
  );
};

const keywordAdCompetitionFormatter = (keywordAdCompetition: any) => {
  const fillRate = Math.max(1, defaultTo(keywordAdCompetition, 0));
  // const rangeIndex = Math.max(1, Math.min(5, Math.ceil(fillRate * 5))); // 0.2, 0.4, 0.6, 0.8, 1
  return (
    <div className={styles.keywordAdCompetitionCell}>
      {Array.from({ length: fillRate }).map((_, index) => (<div key={index} className={styles.fillRateBlock} />))}
    </div>
  );
};

const formatters = {
  nameHeaderFormatter,
  nameFormatter,
  bidPriceFormatter,
  keywordSearchVolumeFormatter,
  growthRateFormatter,
  keywordAdCompetitionFormatter
};
export default formatters;
