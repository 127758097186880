import { RoleNames } from 'core';
import { AppMenuModel, DefaultAppMenuModel, DefaultAppMenuItemModel, AppMenuItemType } from 'containers/App/AppMenuBar';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { hasRoles, notRoles, addOnEnabled } from 'core/permission/PermissionDSL';
import { faChartBar, faCheckSquare, faBriefcase, faCog } from '@fortawesome/free-solid-svg-icons';
import productMenuIcon from 'assets/product-menu-icon.svg';
import _ from 'lodash';

export default function defaultMenus (): Array<AppMenuModel> {

  const { sysAdmin, agencyAdmin, agencyReport, adsReport, agencyManager } = RoleNames;
  const reportMenuItems = [
    new DefaultAppMenuItemModel('appMenus.report.items.performance', '/reports/performance'),
    new DefaultAppMenuItemModel('appMenus.report.items.campaignDashboard', '/reports/campaign-dashboard', hasRoles(sysAdmin).or(hasRoles(agencyAdmin).and(addOnEnabled(ADDONFEATURE.REPORT.REPORT_CAMPAIGN_DASHBOARD)))),
    new DefaultAppMenuItemModel('appMenus.report.items.storedValue', '/stored-value', hasRoles(sysAdmin).or(hasRoles(agencyAdmin, agencyManager).and(addOnEnabled(ADDONFEATURE.COMPANY.STORED_VALUE)))),
    new DefaultAppMenuItemModel('appMenus.report.items.tenmaxReconciliation', '/reports/tenmax-reconciliation', hasRoles(sysAdmin))
  ];
  const reportMenu = new DefaultAppMenuModel('appMenus.report.title', reportMenuItems, faChartBar);

  const companyMenuItems = [
    new DefaultAppMenuItemModel('appMenus.comapnyManagement.items.agencies', '/agencies', hasRoles(sysAdmin, agencyAdmin)),
    new DefaultAppMenuItemModel('appMenus.comapnyManagement.items.advertisers', '/advertisers', hasRoles(sysAdmin, agencyAdmin)),
    new DefaultAppMenuItemModel('appMenus.comapnyManagement.items.accounts', '/accounts', hasRoles(sysAdmin))
    // new DefaultAppMenuItemModel('appMenus.comapnyManagement.items.pmp', '/pmp', hasRoles(sysAdmin))
  ];
  const companyMenu = new DefaultAppMenuModel('appMenus.comapnyManagement.title', companyMenuItems, faBriefcase);

  const retailRMNMenuItems = [
    new DefaultAppMenuItemModel('appMenus.retailRMN.items.products', '/products', notRoles(sysAdmin, adsReport).and(addOnEnabled(ADDONFEATURE.CHANNEL.RTB)))
  ];

  const retailRMNMenu = new DefaultAppMenuModel('appMenus.retailRMN.title', retailRMNMenuItems, productMenuIcon);

  const operationMenuItems = [
    new DefaultAppMenuItemModel('appMenus.operation.items.pchomeRmnAdsPracticalGuide', 'pchome_rmn_ads_practical_guide', null, AppMenuItemType.DOWNLOAD),
    new DefaultAppMenuItemModel('appMenus.operation.items.rmnAdvertisingGuidelines', 'rmn_advertising_guidelines', null, AppMenuItemType.DOWNLOAD),
    new DefaultAppMenuItemModel('appMenus.operation.items.advancedTipsKeywordStrategy', 'advanced_tips_keyword_strategy', null, AppMenuItemType.DOWNLOAD),
    new DefaultAppMenuItemModel('appMenus.operation.items.merchantFaq', 'merchant_faq', null, AppMenuItemType.DOWNLOAD)
  ];
  const operationMenu = new DefaultAppMenuModel('appMenus.operation.title', operationMenuItems, faCog);

  const caseStudyMenuItems = [
    new DefaultAppMenuItemModel('appMenus.rmnInsights.items.monthTrendReport', 'month_trend_report', null, AppMenuItemType.DOWNLOAD)
  ];
  const caseStudyMenu = new DefaultAppMenuModel('appMenus.rmnInsights.title', caseStudyMenuItems, faCog);

  const supportResourceMenuItems = [
    new DefaultAppMenuItemModel('appMenus.supportResource.items.yearEndPromotionPlan', 'year_end_promotion_plan', null, AppMenuItemType.DOWNLOAD)
  ];
  const supportResourceMenu = new DefaultAppMenuModel('appMenus.supportResource.title', supportResourceMenuItems, faCog);

  const advertisementMenuItems = [
    new DefaultAppMenuItemModel('appMenus.ads.items.orders', '/orders', notRoles(sysAdmin, adsReport)),
    new DefaultAppMenuItemModel('appMenus.ads.items.creatives', '/creatives', notRoles(sysAdmin, agencyReport, adsReport))
  ];
  const advertisementMenu = new DefaultAppMenuModel('appMenus.ads.title', advertisementMenuItems, faCheckSquare);

  const menus = _.compact([
    advertisementMenu,
    retailRMNMenu,
    reportMenu,
    companyMenu,
    operationMenu,
    caseStudyMenu,
    supportResourceMenu
  ]);
  return menus;
}
