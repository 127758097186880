import { numberWithCommas } from 'utils/StringUtil';
import { Currency } from 'core';
import _ from 'lodash';

export const divideValue = (numerator: number, denominator: number, decorator?: (value: number) => number) => {
  if (denominator === 0) {
    return 0;
  }
  return decorator ? decorator(numerator / denominator) : _.floor(numerator / denominator, 2);
};

export const percentDecorator = (data: number): string => {
  return fractionDecorator(data) + '%';
};

export const commaDecorator = (data: string | number | undefined): string => {
  return numberWithCommas(data);
};

export const fractionDecorator = (data: number): string => {
  return commaDecorator(_.floor(data, 2).toFixed(2));
};

export const costDecorator = (data: number): string => {
  return `${commaDecorator(_.floor(data, 2).toFixed(2))}`;
};

export const totalCostDecorator = (currency: string, data: number) => {
  let priceValue;
  switch (currency) {
    case Currency.NTD:
      priceValue = _.floor(data, 2).toFixed(2);
      break;
    default:
      priceValue = Math.floor(data).toFixed();
  }
  return `${numberWithCommas(priceValue)}`;
};

export const emptyReportRecord = (dimensionName: string) => {
  return {
    dimensionName: dimensionName,
    clicks: 0,
    impres: 0,
    mediaCost: 0,
    mediaSpent: 0,
    spent: 0,
    underBidFloor: 0,
    winTotal: 0,
    tags: []
  };
};
