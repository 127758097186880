import { AdType, DailyBudgetPlan, DeliverType, RecommendBidPrice, RtbCampaignPlanType, RtbOptimize } from 'core/rtbCampaign/RtbCampaign';
import { AlertModalData, DefaultRtbCampaignBasicFormModel, RtbCampaignBasicFormModel, RtbCampaignBasicFormModelConstructorParams } from './RtbCampaignBasicFormModel';
import { OPERATE } from 'enum/Operate';
import { L1ObjectObjective } from 'core/l1Object/L1Object';
import { L2ObjectOptimizationGoal } from 'core/l2Object/L2Object';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { omitBy, isEmpty, xor, defaultTo } from 'lodash';
import { DefaultPCHomeManager, PCHomeManager } from 'core/pchome/PCHomeManager';
import { CampaignLimitation } from 'core/limitation/Limitation';
import i18n from 'i18n';
import { getLimitationValueContent } from 'utils/LimitationUtil';

const pchomeManager: PCHomeManager = new DefaultPCHomeManager();

export interface KeywordFormModel extends RtbCampaignBasicFormModel {
  readonly canEditBudgetPlan: boolean;
  readonly canEditPriceModel: boolean;
}
abstract class AbstractKeywordFormModel extends DefaultRtbCampaignBasicFormModel implements KeywordFormModel {

  keywords: string[];
  keywordsSuggestThreshold: number = 20;

  constructor (
    mode: 'create' | 'edit',
    ...args: RtbCampaignBasicFormModelConstructorParams
  ) {
    super(mode, ...args);
    this.defaultDeliverType = DeliverType.STANDARD;
    this.keywords = [];
  }

  get campaignAdType (): AdType {
    return AdType.KEYWORD;
  }

  get needSetupAgeGender (): boolean {
    return false;
  }

  get showDeliverType (): boolean {
    return +this.modelDailyBudgetType === DailyBudgetPlan.DAILY;
  }

  get showTags (): boolean {
    return false;
  }

  get showOptimizeSection (): boolean {
    return false;
  }

  get limitationOperates (): {
    need: string[],
    notNeed?: string[],
    other?: string[]
  } {
    return {
      need: [OPERATE.INCLUDE],
      notNeed: undefined,
      other: undefined
    };
  }

  override async fetchRecommendBidPrice (limitationValue: CampaignLimitation): Promise<void> {
    this.updateState(true);
    const searchKeywords = getLimitationValueContent(limitationValue, OPERATE.INCLUDE, 'searchKeywords');
    if (xor(this.keywords, searchKeywords).length === 0) {
      this.updateState(false);
      return;
    }
    const recommendBidPrice: RecommendBidPrice = await pchomeManager.getRecommendBidPrice(searchKeywords);
    this.recommendBidPrice = recommendBidPrice;
    this.keywords = searchKeywords;
    this.updateState(false);
  }

  override processAlertStep (limitationValue: CampaignLimitation, goNext: () => void, goLast: () => void): void {
    const searchKeywords = getLimitationValueContent(limitationValue, OPERATE.INCLUDE, 'searchKeywords');
    if (searchKeywords.length < this.keywordsSuggestThreshold) {
      const hideModalFunc: () => void = () => {
        this.alertModalData = undefined;
        this.updateState(false);
      };
      const confirmFunc: () => void = () => {
        hideModalFunc();
        goNext();
      };
      const retreatFunc: () => void = () => {
        hideModalFunc();
        goLast();
      };
      const alertModalData: AlertModalData = {
        title: i18n.t<string>('common.titles.hint'),
        message: i18n.t<string>('campaign.messages.searchKeywordsAlert', { threshold: this.keywordsSuggestThreshold }),
        dismiss: retreatFunc,
        onCancel: retreatFunc,
        onConfirm: confirmFunc,
        primaryTitle: i18n.t<string>('campaignSetupFlow.labels.nextStep'),
        secondaryTitle: i18n.t<string>('campaign.labels.backToKeywordSetting')
      };
      this.alertModalData = alertModalData;
      this.updateState(false);
    } else {
      goNext();
    }
  }

  getBidPriceRange (optimize: RtbOptimize): {
    max?: number,
    min: number,
    recommend?: {
      min: number,
      max: number
    }
  } {
    const bidFloor = this.getBidFloor(optimize);
    const recommendBidPrice: RecommendBidPrice | undefined = this.state.recommendBidPrice;
    const recommend = !isEmpty(recommendBidPrice) ? {
      min: Math.max(defaultTo(recommendBidPrice.bidFloor, 8), bidFloor),
      max: Math.max(defaultTo(recommendBidPrice.bidCap, 20), bidFloor)
    } : {
      min: Math.max(8, bidFloor),
      max: Math.max(20, bidFloor)
    };
    return {
      max: this.getBidCap(optimize),
      min: bidFloor,
      recommend: recommend
    };
  }

  getRtbOptionsMap () {
    const optionsMap = super.getRtbOptionsMap();
    const enableFCPC = this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CAMPAIGN.FIXED_CPC);
    return omitBy({
      ...optionsMap,
      [L1ObjectObjective.TRAFFIC]: {
        [RtbCampaignPlanType.RS_CPC]: [
          L2ObjectOptimizationGoal.CLICKS
        ],
        [RtbCampaignPlanType.FCPC]: enableFCPC ? [
          L2ObjectOptimizationGoal.CLICKS
        ] : undefined
      }
    }, isEmpty);
  }

  setupDefaultCampaign (campaign: any) {
    super.setupDefaultCampaign(campaign);
    campaign.dailyTargetBudget = 0;
  }
}

export class EditKeywordFormModel extends AbstractKeywordFormModel {
  constructor (...args: RtbCampaignBasicFormModelConstructorParams) {
    super('edit', ...args);
  }
  get canEditBudgetPlan (): boolean {
    return false;
  }
  get canEditPriceModel (): boolean {
    return false;
  }
}

export class CreateKeywordFormModel extends AbstractKeywordFormModel {
  constructor (...args: RtbCampaignBasicFormModelConstructorParams) {
    super('create', ...args);
    this.modelDailyBudgetType = DailyBudgetPlan.DAILY;
  }
  get canEditBudgetPlan (): boolean {
    return true;
  }
  get canEditPriceModel (): boolean {
    return true;
  }
}
